import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import Paging from "components/common/paging";
import PermitParkingSpotItem from './permitParkingSpotItem';

const PermitParkingSpotsTable = ({ parkingSpots, setSelectedParkingSpot, setDisplayPermitsModal }) => {

    const [parkingSpotPages, setParkingSpotPages] = useState();
    const [currentParkingSpotPage, setCurrentParkingSpotPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (parkingSpots) {
            setParkingSpotPages(Math.ceil(parkingSpots.length / 10));
            setCurrentParkingSpotPage(0);
        }
    }, [parkingSpots]);

    if (!parkingSpots) {
        return (null);
    }

    return (
        <>
            <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                <table className="table-auto w-full">
                    <thead>
                        <tr className="text-left bg-airpark-gray-300 h-11">
                            <th className="pl-6">{t('permitParkingSpotsTable.id')}</th>
                            <th className="pl-6">{t('permitParkingSpotsTable.locationSpecific')}</th>
                            <th className="pl-6">{t('permitParkingSpotsTable.number')}</th>
                            <th className="pl-6">{t('permitParkingSpotsTable.features')}</th>
                            <th className="pl-6">{t('permitParkingSpotsTable.permits')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {parkingSpots.slice(currentParkingSpotPage * 10, ((currentParkingSpotPage * 10) + 10)).map((ps, i) => {
                            return (<PermitParkingSpotItem
                                key={i} 
                                parkingSpot={ps}
                                setSelectedParkingSpot={setSelectedParkingSpot}
                                setDisplayPermitsModal={setDisplayPermitsModal} />)
                        })}
                    </tbody>
                </table>
            </div>
            {parkingSpotPages > 0 &&
                <Paging totalNumberOfPages={parkingSpotPages} currentPage={currentParkingSpotPage} setCurrentPage={setCurrentParkingSpotPage} />
            }
        </>
    );
};

export default PermitParkingSpotsTable;