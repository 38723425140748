import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          generic: {
            successTitle: 'Success',
            successDescription: 'Successfully updated',
            errorTitle: 'Something went wrong',
            errorDescription: 'Something went wrong, please try again.',
            missingKeyReplacement: '...',
            yes: 'Yes',
            no: 'No',
            remove: 'Delete',
            close: 'Close',
            back: 'Back',
            edit: 'Edit',
            cancel: 'Cancel',
            ok: 'OK',
            save: 'Save',
            update: 'Update',
            email: 'E-mail',
            phone: 'Phone number',
            search: 'Search',
            create: 'Create',
            missingFieldsTitle: 'Fields missing',
            missingFieldsDescription: 'One or more fields have missing or incorrect information',
            saving: 'Saving',
            uploadImageFiles: "Click to upload images or drag and drop PNG, JPG, JPEG or GIF",
            uploadDocumentFiles: "Click to upload files or drag and drop GIF, JPG, JPEG, PDF or PNG",
            warning: "Warning",
            areyousure: "Are you sure?",
            airPark: 'AirPark',
            share: 'Share',
            add: 'Add',
            invite: 'Invite',
            signin: 'Sign in',
            signup: 'Sign up',
            signout: 'Sign out',
            create: 'Create',
            status: 'Status',
            firstName: 'First name',
            lastName: 'Last name',
            from: 'From',
            to: 'To',
            id: 'Id',
            amount: 'Amount',
            status: 'Status',
            choose: 'Choose',
            change: 'Change',
            revoke: 'Revoke',
            videoInstructions: 'Video instructions',
            updated: 'Updated',
            updatedDescription: 'Updated successfully',
            download: 'Download',
            day: 'Day',
            month: 'Month',
            pending: 'Pending',
            copy: 'Copy',
            copied: 'Copied',
            copiedDescription: 'Copied to clipboard',
            price: 'Price',
            unit: 'Unit',
            termsAndConditionsUrl: 'https://static.airpark.app/policy/2023/terms_and_conditions_en-se.pdf',
            upload: 'Upload',
          },
          error: {
            userAlreadyHaveAnIndividualOrganizationTitle: 'You already have an individual organization',
            userAlreadyHaveAnIndividualOrganizationDescription: 'You can not have multiple individual organizations'
          },
          legend: {
            totalAmount: 'Total revenue',
            bookingsAmount: 'Bookings',
            permitsAmount: 'Permits',
            controlFeesAmount: 'Control fees',
            revenue: 'Revenue',
          },
          dayShortNames: {
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat',
            sunday: 'Sun'
          },
          daySingularNames: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday'
          },
          dayPluralNames: {
            monday: 'Mondays',
            tuesday: 'Tuesdays',
            wednesday: 'Wednesdays',
            thursday: 'Thursdays',
            friday: 'Fridays',
            saturday: 'Saturdays',
            sunday: 'Sundays'
          },
          navigation: {
            'parking-areas': 'Parking areas',
            'permits': 'Permits',
            'queue': 'Queue',
            'community': 'Community',
            'control-fees': 'Control fees',
            'marketplace': 'Marketplace',
            'parkingspots': 'Parking spots',
            'settings': 'Settings',
            'users': 'Users',
            'connect-requests': 'Connect requests',
            'reports': 'Reports',
            'payouts': 'Payout',
            'revenue': 'Revenue',
            'occupancy': 'Occupancy',
            'payout': 'Payouts',
            'signs': 'Signs'
          },
          paging: {
            pageOfTotal: 'Page {{page}} of {{total}}'
          },
          priceUnit: {
            hour: 'hour',
            day: 'day',
            month: 'month',
            onetime: 'one-time',
            kWh: 'kWh',
          },
          defaultLayout: {
            loading: 'Loading...'
          },
          loginPage: {
            byLoggingInYouApprove: "By signing in you approve of the",
            conditions: "conditions",
            rememberMe: "Remember me",
            mobileNumber: "Mobile number",
            selectCountryCode: "Select country/code",
            search: "search",
            loginWithMobileNumber: 'Login with mobile number',
            loginWithEmail: 'Login with e-mail',
            emailIsNotValid: 'E-mail is not valid',
            noAccountSignup: 'No account? Register here',
            useTheApp: 'Did you know that it\'s super easy to rent out your parking space with the AirPark app? Download it here',
          },
          verifySmsCodePage: {
            verifyCode: "Enter code",
            enterTheCodeThatWasSentTo: "Enter the code that was sent to",
            didNotReceiveCode: "Did not receive a code? Try again",
            newCodeSentTitle: "New code sent",
            newCodeSentDescription: "A new code has been sent to phone number",
            codeCouldNotBeVerifiedTitle: "Code not valid",
            codeCouldNotBeVerifiedDescription: "Your code couldn't be verified. Please try again."
          },
          registerPage: {
            beforeWeGetStarted: "Before we get started",
            description: "In order to be part of the service and provide you with a fantastic experiene we need a little more information.",
            firstName: "Firstname",
            surName: "Surname",
            country: 'Country',
            accept: 'I accept AirPark\'s',
            termsAndConditions: 'terms and conditions',
          },
          chooseOrganizationPage: {
            chooseOrganization: "Choose organization",
            description: "Available organizations that you can administer parkings for.",
            createNewOrganization: "Create new organization",
            noOrganizationAvailableTitle: "No organization available",
            noOrganizationAvailableDescription: "No organization available. Create an organization to organize your parkings."
          },
          createOrganizationPage: {
            createOrganization: "Create organization",
            description: "Create an organization for your parkings. This could be your name, the name of your housing cooperative or company.",
            nameMissingTitle: 'Name missing',
            nameMissingDescription: 'You need to fill out the name of the organization.',
            typeOfOrganization: 'Company or individual?',
            typeOfOrganizationTooltip: 'Choose which organization type you are signing up as. Anything other than individual should choose company as organization type.',
            company: 'Company',
            individual: 'Individual',
            country: 'Country',
            name: 'Name',
            orgNbr: 'Organization number',
            socialSecurityNumber: 'Social Security number',
            address: 'Address',
            zipCode: 'Zip code',
            city: 'City',
            phone: 'Phone number',
            email: 'E-mail',
            youMustAcceptTermsAndConditionsTitle: 'You must accept terms and conditions',
            youMustAcceptTermsAndConditionsDescription: 'You must accept the terms and conditions to continue.',
            missingRequiredInfoTitle: 'Required info missing',
            missingRequiredInfoDescription: 'You need to fill out all of the required information.',
          },
          leftMenu: {
            start: "Start",
            areas: "Areas",
            reports: "Reports",
            users: "Users",
            settings: "Settings",
            payouts: 'Payouts',
            occupancy: 'Occupancy',
            revenue: 'Revenue',
            payoutSettings: 'Payout account',
            revenueSharing: 'Revenue sharing',
            statistics: 'Statistics',
          },
          leftMenuParkingArea: {
            parking: "Parking",
            permits: "Permits",
            queue: "Queue",
            controlfees: "Control fees",
            community: "Community",
            parkingSpots: "Parking spots",
            marketplace: "Marketplace",
            communityConnectRequests: 'Connect requests',
            controlFeesSettings: 'Control fees settings',
            signs: 'Sign material',
            charging: 'Charging',
            linkedCommunities: 'Linked communities',
            airPass: 'AirPass',
            customAgreement: 'Custom agreement',
            bookings: 'Bookings',
          },
          getStartedWidgets: {
            welcome: "Welcome to AirPark!",
            hello: "Hi {{name}}!",
            intro: "This is your start page, as you begin to activate and offer parkings we'll be able to provide you with data and key insights here.",
            letsgo: "Let's get started!",
            createParkingAreasTitle: "Parking areas",
            createParkingAreasDescription: "Parking areas are locations where you can add parking spots with specific features.",
            addBasicInformationTitle: "Basic information",
            addBasicInformationDescription: "Add some basic information about your parkings. Such as availability, base price, and so forth.",
            addPriceRulesAndRewardTitle: "Price rules and rewards",
            addPriceRulesAndRewardDescription: "You can create custom price rules to increase your revenue at peak hours. You can also create rewards for your customers when they use your parkings!",
            toParkingAreas: 'To parking areas'
          },
          parkingAreas: {
            noAreasTitle: "No areas added",
            noAreasDescription: "Start by adding an area which contains one or more parkings.",
            areas: "Areas",
            addArea: "Add area",
            pin: "Pin",
            name: "Name",
            parkings: "Parkings",
            noParkingAreas: "No parking areas",
            videoInstructionsDescription: "Watch our video tutorial on how to add a parking area.",
          },
          topBar: {
            organizations: "Organizations",
            addOrganization: "Create organization",
            logout: 'Logout'
          },
          createParkingArea: {
            name: "Name",
            basics: "Area information",
            hour: "hour",
            day: "day",
            oneTimeFee: "one-time fee",
            position: "Position",
            basePrice: "Base price",
            positionDescription: "Find area, zoon in, and then draw the area by adding corners of the area.",
            removePosition: "Remove area",
            detailsHeader: "Number of spots and features",
            detailsDescription: "State how many spots are outdoors and indoors. Also state what kind of features are available.",
            indoors: "Indoors",
            outdoors: "Outdoors",
            chargingPost: "Charging box",
            fenced: "Fenced",
            information: "Information",
            photo: "Photo",
            totalNumberOfParkingSpots: "Total {{totalNumberOfParkingSpots}} parking spots",
            confirm: 'Confirm parking area',
            confirmDescription: 'We need one or more documents that validates that it is OK to add this parking area. Please upload the documents below. AirPark will then review these and confirm the authenticity before the parking area will be available for end-users. Thanks!',
            address: 'Address',
            noAddress: 'Draw an area on the map to set the address of the parking area',
            changeAddress: 'Not correct address? Enter the address manually',
            city: 'City',
            zipCode: 'Zip code',
            country: 'Country',
            search: 'Search...',
            notnumbered: 'Not numbered',
            numbered: 'Numbered',
            add: 'Add',
            numberedTitle: 'Are the parking spots numbered?',
            features: 'Features',
            from: 'From',
            to: 'To',
            parkingSpots: 'Parking spots',
            addParkingSpots: 'Add parking spots',
            parkingAreaDescription: 'Description',
            creatingParkingArea: 'Creating...',
            parkingAreaDetailsTitle: 'Description',
            parkingAreaDetailsDescription: 'Provide any relevant information about the parking area.',
            inReviewTitle: '{{name}} sent in for approval',
            inReviewDescription: 'AirPark will now review the documentation provided and your parking area. In the meantime you are free to set-up price rules and availability for the parking area.',
            amount: 'Number of parking spots',
            somethingWentWrongTitle: 'Something went wrong',
            somethingWentWrongDescription: 'Something went wrong when the parking area was supposed to be created. Please try again. Please contact AirPark support if the problem persists.',
            fileNotValidTitle: 'File not valid',
            fileNotValidDescription: 'The file was not valid. Please use PNG, JPG, JPEG or GIF with a maximum size of 10MB.'
          },
          parkingAreaMap: {
            removeArea: 'Remove area'
          },
          parkingArea: {
            edit: 'Edit',
            description: 'Description',
            noDescription: 'No description for {{name}}',
            free: 'free',
            add: 'Add',
            yes: 'Yes',
            no: 'No',
            downloadSigns: 'Download signs',
            notNumbered: 'Not numbered',
            features: 'Features',
            locationSpecific: 'Location specific',
            indoors: 'Inomhus',
            outdoors: 'Utomhus',
            fenced: 'Inhägnat',
            hasChargingPost: 'Laddbox',
            id: 'Id',
            number: 'Number',
            parkings: 'Parking spots',
            awaitingApprovalTitle: '{{name}} is in review',
            awaitingApprovalDescription: '{{name}} is being reviewed by AirPark and is awaiting approval. In the meantime you can add parking spots, price rules and availablity. The parking area will not be available in the app until AirPark has validated that ownership of the area.',
            availability: 'Availability & Pricing',
            outsideAvailabilityHours: 'Outside availability hours',
            updatedTitle: 'Updated',
            updatedDescription: 'Parking area is now updated',
            parkingNotAllowed: 'Parking not allowed',
            parkingFree: 'Free parking',
            startDate: 'Start date',
            endDate: 'End date',
            period: 'Period',
            days: 'Days',
            time: 'Time',
            parkingSpots: 'Parking spots',
            availableFor: 'Available for',
            price: 'Price',
            allDays: 'Any',
            public: 'Public',
            allParkingSpots: 'Any',
            specificDays: 'Specific days',
            community: 'Community',
            specificSpots: 'Specific spots',
            removePriceRuleSetTitle: 'Delete?',
            removePriceRuleSetDescription: 'Are you sure you want to remove the price & availability row?',
            removedPriceRuleSetTitle: 'Deleted',
            removedPriceRuleSetDescription: 'Successfully deleted price rule set',
            missingPriceRuleSets: 'You need to add price and availability if you want customers to be able to book parkings in the AirPark app.',
            removedParkingSpotTitle: 'Deleted',
            removedParkingSpotDescription: 'Successfully deleted parking spot',
            removeParkingSpotTitle: 'Delete?',
            removeParkingSpotDescription: 'Are you sure you want to delete the parking spot?',
            approvedTitle: 'Approved',
            approvedDescription: 'The parking area is now approved by AirPark. Add price rules and availability if you want to make it available to book in the AirPark app.',
            asDefinedByPeriod: 'As defined by the period',
            removeParkingAreaTitle: 'Remove area',
            removeParkingAreaDescription: 'Are you sure you want to remove the parking area? This cannot be undone!',
            ongoing: 'Ongoing',
            removedParkingAreaTitle: 'Removed',
            removedParkingAreaDescription: 'Parking area was removed',
            videoInstructions: 'How to configure availability and pricing',
            videoInstructionsDescription: 'Watch this video to learn how to configure availability and pricing for your parking area.',
          },
          addPriceRuleSet: {
            title: 'Add price & availability',
            description: 'Name / description',
            from: 'From',
            to: 'To',
            pickParkingSpots: 'Choose parking spots',
            all: 'All',
            specificSpots: 'Specific',
            selectedNumberOfSpots: '{{number}} spots selected',
            onWeekdays: 'On weekdays',
            public: 'Public',
            community: 'Community',
            availability: 'Days',
            betweenDates: 'All',
            specificDays: 'Specific days',
            save: 'Save',
            between: 'Between',
            errorTitle: 'Something went wrong',
            errorName: 'You need to enter a description/name',
            errorParkingSpots: 'No parking spots selected',
            errorDateRange: 'From date cannot be larger than to date',
            errorPrice: 'Price cannot be empty or 0',
            priceRuleSetUpdatedTitle: 'Updated',
            priceRuleSetUpdatedDescription: '{{name}} is now updated',
            priceRuleSetAddedTitle: 'Added',
            priceRuleSetAddedDescription: '{{name}} is now added for the parking area',
            price: 'Price',
            availableFor: 'Available for',
            limitNumberOfHoursParkingIsAllowed: 'Set max parking time?',
            maxParkingTimeInHours: 'Max parking time in hours',
            tooltipAvailability: 'Choose Between dates if the price and availability is for the whole period. Choose Recurring days if the price and availability is for specific days of the week and hours of the day – e.g. Mon - Fri 1pm - 6pm.',
            tooltipDescription: 'Give the price and availablity a name or description. This is only visible for you in the portal and won\'t be shown in the AirPark app.',
            editTitle: 'Edit price & availablity',
            noEndDate: "Until further notice",
            ongoing: 'ongoing',
            chooseCommunity: 'Choose community',
            noCommunitiesTitle: 'No communities',
            noCommunitiesDescription: 'No communities available for the parking area. Go to the communities section to create a community.',
            recurring: 'Recurring',
            recurringTo: 'Recurring to',
            between: 'Between',
            and: 'and',
          },
          selectParkingSpotsModal: {
            selectParkingSpots: 'Select parking spots',
            selectParkingSpot: 'Select parking spot',
            yes: 'Yes',
            no: 'No',
            notNumbered: 'Not numbered',
            features: 'Features',
            locationSpecific: 'Location specific',
            id: 'Id',
            number: 'Number',
            numberOfParkingSpotsSelected: '{{number}} parking spots selected',
            noParkingSpotsAvailable: 'No parking spots available'
          },
          editParkingSpotModal: {
            editParkingSpot: 'Edit parking spot',
            parkingSpotActive: 'Parking spot available',
            parkingSpotDisabled: 'Parking spot unavailable',
            outdoors: 'Outdoors',
            indoors: 'Indoors',
            fenced: 'Fenced',
            chargingPost: 'Charging box',
            features: 'Features',
            description: 'Description',
            positionDescription: 'If the parking spot is numbered you can give it an exact position. Place the pin on the parking area where the parking spot is located.',
            positionSpecific: 'Numbered',
            position: 'Position',
            number: 'Number',
            removePin: 'Remove pin',
            parkingSpotUpdatedTitle: 'Updated',
            parkingSpotUpdatedDescription: 'Parking spot is now updated'
          },
          addParkingSpotsModal: {
            title: 'Add parking spots',
            addedParkingSpotsTitle: 'Added',
            addedParkingSpotsDescription: 'Parking spots added to the parking area'
          },
          editParkingArea: {
            approvalRequiredTitle: 'Approval required',
            approvalRequiredDescription: 'A new approval is required from AirPark if the parking area\'s information is updated. During the approval process it won\'t be possible to issue any new permits or for customers to make any new bookings in the AirPark app.',
            description: 'Description',
            updatedTitle: 'Updated',
            updatedDescription: 'The parking area is now updated.',
            updatedDescriptionRequiresApproval: 'The parking area is now updated. AirPark will review the changes and get back to you as soon as possible.',
            uploadImages: 'Upload images',
            parkingAreaHasNoImages: 'The parking area has no images. Upload images and save to create a better presentation of the area in the AirPark app.',
            fieldsMissingTitle: 'Fields missing',
            fieldsMissingDescription: 'One or more fields are missing. Please fill in all fields and try again.',
          },
          createPermitPage: {
            createTitle: 'Create permit',
            issueTitle: 'Issue permit',
            phoneNumber: 'Recipient mobile phone number',
            phoneNumberTooltip: 'A text message with a link to the permit will be sent to the recipient. The permit will be valid first after a successful payment.',
            description: "Description",
            descriptionTooltip: "Give the permit a description. This will only be visible in the portal. The recipient\'s name will be displayed on the permit after the permit has been activated.",
            chooseParkingSpot: "Choose parking spot",
            allParkingSpots: "Any",
            specificParkingSpots: "Specific spots",
            period: 'Days',
            betweenDates: 'All',
            specificDays: 'Specific days',
            tooltipPeriod: 'When choosing all days the permit is valid throughout the selected period for any day in the period. Specific days allows to specify days and hours when the permit should be valid within the selected period – e.g. Mon-Fri 7am-6pm.',
            spotSelected: 'Spot selected',
            noSpotSelected: 'No spot selected',
            from: 'From',
            to: 'To',
            onWeekdays: 'On weekdays',
            between: 'Between',
            isSublettingAllowed: 'Is subletting allowed',
            canBeSublettedTo: 'Can be subletted to',
            isSublettingAllowedDescription: 'Choose if the permit is allowed to be subletted. The permit can be restricted to only subletting within a community.',
            isSublettingAllowedMarketplaceDescription: 'Choose if the permit is allowed to be subletted.',
            community: 'Community',
            everyone: 'Anyone',
            invoicedBy: 'Invoiced by',
            externally: 'Other party',
            price: 'Price',
            invoicedByTooltip: 'Choose how payments should be handled. AirPark can allow payments for the permit directly in the AirPark app and update the permit\'s status accordingly. If a third party manages the payments the status of the permit must be updated manually.',
            invoicedExternallyTitle: 'Other party',
            invoicedExternallyDescription: 'The payments are managed by a third party. Any administration in regards to payments of the invoice must be handled manually.',
            noEndDate: 'Ongoing',
            permitCreatedTitle: 'Permit created',
            permitCreatedDescription: 'The permit has been created and a text message has been sent to {{phone }} with an invitation link. When the recipient has accepted the permit their name will be displayed on the permit.',
            editTitle: 'Edit permit',
            permitUpdatedTitle: 'Updated',
            permitUpdatedDescription: 'Permit updated.',
            marketplaceAvailability: 'Available on the marketplace for',
            marketplacePermitCreatedTitle: 'Permit created',
            marketplacePermitCreatedDescription: 'Permit is created and available on the marketplace.',
            marketplacePermit: 'Choose if the permit should be available to purchase by anyone or if it should only be available to purchase by members of a community.',
            addUserToCommunity: 'Add recipient to the parking area\'s communities',
            addUserToCommunityTooltip: 'Choose if the user should be added to the communities created for the parking area when the permit is accepted.',
            noCommunitiesFoundTitle: 'No communities found',
            noCommunitiesFoundDescription: 'The parking area has no communities. Create a community in the parking area\'s settings.',
            userWillBeAddedToCommunitiesTitle: 'Communities',
            userWillBeAddedToCommunitiesDescription: 'The user will be added to the following communities when the permit is accepted.',
            issue: 'Issue permit',
            parkingSpots: 'Parking spots'
          },
          permitsPage: {
            permitsPageTitle: 'Permits',
            permitsPageDescription: 'Administer and issue permits for the parking area or specific parking spots. When a permit is issued the recipient receives a text message with an invitation link.',
            addPermitTitle: 'Permit',
            createPermit: 'Create',
            expired: 'Expired',
            unpaid: 'Unpaid',
            activePermits: 'Active',
            parkingSpotsWithoutPermits: 'Parking spots without permits',
            permitsInMarketplace: 'Permits in marketplace',
            videoInstructionsDescription: 'Watch our video on how to issue parking permits',
          },
          inputPhoneNumber: {
            chooseCountryRegion: 'Choose country/region'
          },
          permitTable: {
            notAcceptedYet: 'Pending',
            name: 'Name',
            description: 'Description',
            spotIdentifier: 'Spot identifier',
            timePeriod: 'Period',
            id: 'Id',
            status: 'Status',
            firstName: 'First name',
            lastName: 'Last name',
            phoneNumber: 'Phone number',
            licensePlateNumber: 'License plate',
            period: 'Period',
            time: 'Hours',
            price: 'Price',
            subletting: 'Subletting',
            duplicate: 'Duplicate',
            revoke: 'Revoke',
            days: 'Days',
            parkingArea: 'Whole area',
            noPermits: 'No permits issued. Click Create to issue a permit.',
            noMarketplacePermits: 'No permits available on the marketplace.',
            noPermitsFound: 'No permits found',
            active: 'Active',
            pending: 'Invitation sent',
            allowed: 'Allowed',
            notallowed: 'Not allowed',
            invoicedBy: 'Invoiced by',
            thirdParty: 'Other',
            revokePermitTitle: 'Revoke permit?',
            revokePermitDescription: 'The permit will be revoked on {{date}}. Note that if the permit should has a longer cancellation period you can update it manually and set a specific end date.',
            revokedPermitTitle: 'Permit revoked',
            revokedPermitDescription: 'The permit is now revoked and not valid anymore.',
            expired: 'Expired',
            ongoing: 'Ongoing',
            marketplace: 'Available on marketplace',
            availability: 'Available for',
            community: 'Community',
            everyone: 'Public',
            deletePermitTitle: 'Delete?',
            deletePermitDescription: 'This will delete the permit.',
            deletedTitle: 'Deleted',
            deletedDescription: 'The permit was deleted.'
          },
          dropDownMultiSelector: {
            filter: 'Filter'
          },
          verifyEmailPage: {
            verifyEmailTitle: 'Confirm e-mail address',
            verifyEmailDescription: 'Confirm the e-mail adderss by clicking the link that was sent to {{email}}',
            emailCouldNotBeVerifiedTitle: 'Not verified',
            emailCouldNotBeVerifiedDescription: 'The e-mail address could not be verified. Please try again.',
            noVerificationEmailReceivedTitle: 'No e-mail received?',
            noVerificationEmailReceivedDescription: 'If you have not received an e-mail with a link to confirm your e-mail address, please check your spam folder. If you still have not received an e-mail, please try to request a new e-mail.',
            resendVerificationEmail: 'Resend e-mail',
            emailResentTitle: 'E-mail resent',
            emailResentDescription: 'A new e-mail with a link to confirm your e-mail address has been sent to {{email}}.'
          },
          login: {
            loginInProgress: 'Login in progress...'
          },
          permitMarketplacePage: {
            title: 'Marketplace',
            create: 'Create',
            description: 'Create parking permits that become available on the marketplace in the AirPark app. The parking permits can be made available for the general public or restricted to communities connected to the parking area.'
          },
          permitShare: {
            shareTitle: 'Share permit',
            shareDescription: 'Share a permit for the permit. The link can be shared by e-mail or using social media such as Facebook.',
            createdTitle: 'Permit created',
            createdDescription: 'The permit has been created and is available on the marketplace in the AirPark app. The permit can also be shared by e-mail or social media using the link below.',
            updatedTitle: 'Permit updated',
            updatedDescription: 'Permit updated and available on the marketplace in the AirPark app. The permit can also be shared by e-mail or social media using the link below.',
            copyLink: 'Copy the link to the clipboard',
            linkCopiedTitle: 'Copied',
            linkCopiedDescription: 'Link copied and available on the clipboard'
          },
          settingsPage: {
            title: 'Settings',
            description: 'Manage the settings for your organization.',
            name: 'Name',
            organizationNumber: 'Organization number',
            address: 'Address',
            zipCode: 'Zip Code',
            city: 'City',
            country: 'Country',
            phoneNumber: 'Phone number',
            email: 'Email',
            organizationUpdatedTitle: 'Updated',
            organizationUpdatedDescription: 'Organization was updated successfully',
            vatPercentage: 'VAT percentage',
            invoiceEmail: 'Invoice email',
            selfBilling: 'Self billing',
            selfBillingTooltip: 'Choose if you want to use self billing. Self billing means that you will pay the VAT manually. If you choose not to use self billing AirPark will pay the VAT for you, which will result in a higher fee for the service.',
            signAndSave: 'Sign and save',
            taxIdentificationNumber: 'Tax identification number',
            sign: 'Sign',
          },
          payoutSettingsPage: {
            stripeTitle: "Payout Account",
            stripeDescription: "AirPark uses the global payment provider Stripe for payouts.",
            stripeNotConfiguredTitle: "Stripe is not configured",
            stripeNotConfiguredDescription: "Stripe is not configured for your organization. Stripe needs to be configured in order to receive payouts.",
            configureStripe: "Configure Stripe",
            payoutDayOfMonthAndMinimumValueTitle: "Payout Day and Minimum Amount",
            payoutDayOfMonthAndMinimumValueDescription: "The day of the month when payouts are made and the minimum payout amount.",
            minimumPayoutValueErrorTitle: "Minimum payout amount is too low",
            minimumPayoutValueErrorDescription: "The minimum payout amount must be at least 300.",
            stripeConfiguredTitle: 'Stripe configured',
            stripeConfiguredDescription: 'Stripe is configured and linked to your AirPark account. Your organization can now accept payments.',
            payoutDayOfMonth: "Payout Day",
            minimumPayoutValue: "Minimum Payout Amount",
            minimumPayoutValueTooltip: "The minimum payout amount is the lowest amount that can be paid out. If the total revenue for a month is less than the minimum payout amount, the revenue will be rolled over to the next month.",
            dac7title: "DAC7",
            dac7description: "To enable AirPark to process payouts of your earnings, it is necessary for you to provide the above information.\n\nWe collect this information to ensure compliance with DAC7, the new EU law that came into effect on January 1, 2023, requiring us to report earnings from parking rentals to the Swedish Tax Agency.\n\nIf you wish to enable self-billing, you also need to provide your VAT registration number.\n\nBy providing this information and signing with BankID, you assure us that the information is correct and up-to-date.",
            sign: "Sign",
            dac7NotSignedTitle: "Not Signed",
            dac7NotSignedDescription: "The information has not been signed with BankID. To receive payouts and for self-billing, the information must be signed.",
            dac7SignedTitle: "Signed",
            dac7SignedDescription: "The information has been signed with BankID."
          },
          revenueSharingSettingsPage: {
            revenueSharingTitle: 'Revenue sharing',
            revenueSharingDescription: 'Configure the revenue sharing for your organization. The revenue sharing is used to calculate the revenue share for subletting. Please note that AirPark fees are not included in the revenue sharing.',
            revenueSharingSublettingTitle: 'Subletting',
            forYou: 'For you',
            subletter: 'Subletter',
            shareIsNotValidTitle: 'Share is not valid',
            shareIsNotValidDescription: 'The share is not valid. The sum of the shares must be 100.',
          },
          usersPage: {
            title: 'Manage users',
            description: 'Manage the users that have access to your organization. A portal administrator can see and administer everything in your portal, a sublessor can set your organization as recipient for payouts from subleasing.',
            users: 'Users',
            sublessors: 'Sublessors',
            portalAdministrators: 'Portal administrators',
            invites: 'Pending invites',
          },
          usersTable: {
            firstName: 'First name',
            lastName: 'Last name',
            email: 'E-mail',
            phone: 'Phone number',
            removeUserTitle: 'Remove user?',
            removeUserDescription: 'Are you sure you want to remove the user? The user will lose all access to this organization.',
            removedUserTitle: 'Removed user',
            removedUserDescription: 'Successfully removed user from organization',
            contact: "Contact",
            invitationStatus: "Invitation Status",
            sent: "Sent",
            accepted: "Accepted",
            accessLevel: "Access level",
            portalAdmin: "Portal admin",
            sublessor: "Sublessor",
          },
          invitesTable: {
            phone: 'Phone number',
            status: 'Status',
            issuedBy: 'Issued by',
            contact: 'Contact',
            noInvites: 'No invites pending',
            deleteInviteTitle: 'Delete invite?',
            deleteInviteDescription: 'Are you sure you want to remove the invite? This cannot be undone.',
            removedInviteTitle: 'Invite removed',
            removedInviteDescription: 'Successfully removed organization invite',
            sentAt: 'Sent at',
          },
          inviteUserModal: {
            title: 'Invite user',
            descriptionPortalAdmin: 'Send an invite to the user that you want to join your organization as a portal admin.',
            descriptionSublessor: 'Send an invite to the user that you want to join your organization as a sublessor.',
            userInvitedTitle: 'User invited',
            userInvitedDescription: 'User has been invited to join your organization.',
            portalAdmin: 'Portal admin',
            sublessor: 'Sublessor',
          },
          inviteSublessorModal: {
            userInvitedTitle: 'User invited',
            userInvitedDescription: 'User has been invited to join your organization as a sublessor.'
          },
          organizationInvitationPage: {
            youveBeenInvitedTitle: 'You\'ve been invited',
            youveBeenInvitedDescription: 'You\'ve been invited to join the {{organizationName}} organization.',
            useEmail: 'Login or sign-up with the e-mail address',
            acceptInvite: 'Accept invite',
            invitationNotFoundTitle: 'Not found',
            invitationNotFoundDescription: 'Sorry, the invitation was not found.',
            emailDoesNotMatchTitle: 'Wrong e-mail',
            emailDoesNotMatchDescription: 'The e-mail you\'re logged in with does not match the e-mail the invitation was sent to.',
            emailNotVerifiedTitle: 'Not verified',
            emailNotVerifiedDescription: 'You need to verify your e-mail before you can accept the invitation.'
          },
          communityPage: {
            title: 'Community',
            description: 'Communities can be used to create specific accessibility and pricing for parking areas, parking spots and pricing. It\'s also possible to connect with other communities to expand what your community members have access to.',
            communitiesTitle: 'Communities',
            addExistingCommunity: 'Add existing community',
            videoInstructionsDescription: 'Watch our video on what communities are and how to use them.',
          },
          createCommunityPage: {
            title: 'Create community',
            name: 'Name',
            description: 'Description',
            photos: 'Upload photo',
            photosTooltip: 'Upload an image that represents the community. Only a single image is allowed.'
          },
          communitiesTable: {
            name: 'Name',
            numberOfMembers: 'Number of members',
            description: 'Description',
            noCommunities: 'No communities',
            manageMembers: 'Manage members',
            noMembersManageMembers: 'No members. Invite members?',
            editCommunity: 'Edit community',
          },
          manageCommunityPage: {
            title: 'Manage community',
            uploadPhotos: 'Upload photo',
            photos: 'Photo',
            photosTooltip: 'Upload an image that represents the community. Only a single image is allowed.',
            noPhotos: 'No photo uploaded for this community',
            communityUpdatedTitle: 'Community updated',
            communityUpdatedDescription: 'Community was updated successfully'
          },
          manageCommunityMembersPage: {
            title: 'Manage community members',
            members: 'Members',
            inviteMember: 'Invite member',
            phoneNumber: 'Phone number',
            pendingInvites: 'Pending invitations',
            phoneNumberMissingTitle: 'Phone number missing',
            phoneNumberMissingDescription: 'A phone number is required to send an invitation',
            invitationSentTitle: 'Invitation sent',
            invitationSentDescription: 'Invitation sent to the member',
            sendPhoneInvitationTitle: 'Send phone invitation',
            sendPhoneInvitationDescription: 'Send an invitation to the member via SMS',
            createLinkInvitationTitle: 'Create invitation link',
            createLinkInvitationDescription: 'Create an invitation link that can be sent via e-mail or other channels',
            invitationLinkCreatedTitle: 'Invitation link created',
            invitationLinkCreatedDescription: 'Invitation link created. Copy the link and send it to the member.',
            noInvitationLinksCreated: 'No invitation links created',
          },
          communityMembersTable: {
            firstName: 'First name',
            lastName: 'Last name',
            phoneNumber: 'Phone',
            noMembers: 'No members'
          },
          communityInviteLinksTable: {
            link: 'Link',
            noLinks: 'No links'
          },
          connectedCommunitiesTable: {
            communityName: 'My community',
            connectedCommunityName: 'Connected with',
            noConnectedCommunities: 'No connected communities',
            deleteCommunityConnectionTitle: 'Delete community connection',
            deleteCommunityConnectionDescription: 'Are you sure you want to delete the community connection?',
            removedCommunityConnectionTitle: 'Community connection deleted',
            removedCommunityConnectionDescription: 'Successfully deleted community connection'
          },
          createConnectCommunityRequestModal: {
            title: 'Connect with community',
            stepsStart: 'To find a parking area and connect with a community, please follow these steps:',
            stepOne: 'Use the map to find a parking area and community you want to connect with. After selecting an area, a list of communities will appear.',
            stepTwo: 'Select the community that you want to connect with. If you have more than one community you need to select which of your community you want to create the connection for.',
            stepThree: 'Send the request to the community. The community will then be able to accept or decline the request.',
            stepsEnd: 'If the request is accepted, you will be able to see the community in the list of connected communities.',
            sendConnectRequest: 'Send connect request',
            selectCommunity: 'Select community to request a connection with',
            selectYourCommunity: 'Select which of your communities you want to request the connection for',
            noCommunitySelectedTitle: 'No community selected',
            noCommunitySelectedDescription: 'Use the map to find and select a parking area and community to connect with.',
            youCantConnectWithYourOwnParkingAreaTitle: 'That\'s not possible',
            youCantConnectWithYourOwnParkingAreaDescription: 'You can\'t connect with your own parking area.',
            noCommunitiesCreatedForParkingAreaTitle: 'No communities',
            noCommunitiesCreatedForParkingAreaDescription: 'There are no communities created for your parking area. Create a community first and then try again.',
            connectRequestSentTitle: 'Request sent',
            connectRequestSentDescription: 'Request sent to the community. The community will be able to accept or decline the request.',
          },
          communityConnectionsPage: {
            title: 'Community connections',
            description: 'On this page, you will find a list of requests that have been sent to your community, as well as requests that you have sent to other communities. You have the option to accept or decline these requests.',
            sentRequests: 'Sent requests',
            receivedRequests: 'Received requests',
            connectedCommunitiesTitle: 'Connected communities',
            connect: 'Connect',
            pendingRequests: 'Pending requests',
          },
          sentRequestsTable: {
            myCommunity: 'My community',
            sentToCommunity: 'Sent to community',
            noPendingRequests: 'No pending requests',
            deleteSentRequestTitle: 'Delete sent request',
            deleteSentRequestDescription: 'Are you sure you want to delete the sent request?',
            removedSentRequestTitle: 'Request deleted',
            removedSentRequestDescription: 'The sent request was deleted successfully',
          },
          receivedRequestsTable: {
            receivedFromCommunity: 'Received from community',
            myCommunity: 'My community',
            noPendingRequests: 'No pending requests',
            deleteReceivedRequestTitle: 'Delete received request',
            deleteReceivedRequestDescription: 'Are you sure you want to delete the received request?',
            removedReceivedRequestTitle: 'Request deleted',
            removedReceivedRequestDescription: 'The received request was deleted successfully',
          },
          communityConnectRequestPage: {
            title: 'Community connect request',
            howDoesItWorkTitle: 'How does a community connection work?',
            howDoesItWorkDescription: 'If you accept this invitation, your parking community will be connected with the inviting community. This will allow the members of the inviting community to access the parking areas and parking spots that are associated with your community\'s price and availability rules, and the members of your community will be able to access the inviting community\'s resources.',
            acceptRequest: 'Accept connect request',
            acceptedTitle: 'Request accepted',
            acceptedDescription: 'The request was accepted successfully',
            notFoundTitle: 'Request not found',
            notFoundDescription: 'The request was not found',
            communityWantsToConnect: '{{communityName}} wants to connect with your community {{communityRecipientName}}.',
            noAccessToAcceptTitle: 'No access',
            noAccessToAcceptDescription: 'You don\'t have access to accept this request',
          },
          notificationsMenu: {
            title: 'Notifications',
            noNotifications: 'No notifications',
          },
          permitQueuePage: {
            title: 'Permit queue',
            description: 'On this page, you will find a list of all the users that are currently in the queue for a permit. You can also see the number of users that are currently in the queue for a permit.',
            usersInQueue: 'Users in queue',
          },
          permitQueueTable: {
            inQueueSince: 'In queue since',
            noUsersInQueue: 'No users in queue',
            specialRequests: 'Special requests',
            nobodyInQueue: 'Nobody in queue',
            issuePermit: 'Issue permit',
          },
          dashboardPage: {
            greeting: 'Hi, {{name}}!',
            createParkingAreaTitle: 'Create a parking area',
            createParkingAreaDescription: 'To get started, create a parking area. You can create multiple parking areas if you have more than one parking area. Afterwards you can create availability and price rules for your parking areas.',
            hereIsYourAirParkOverview: 'Here is your AirPark overview',
            bookingsLastTwentyFourHours: 'Bookings last 24 hours',
            activeParkingPermits: 'Active parking permits',
            totalPayouts: 'Total payouts',
            pendingPayouts: 'Pending payouts',
            revenue: 'Revenue',
            revenueDescription: 'Your revenue for the last 12 months, after AirPark fees and transaction fees have been deducted.',
            lastTwelveMonths: 'Last 12 months',
            noRevenueYet: 'No revenue yet',
          },
          statisticsPage: {
            title: 'Statistics',
            description: 'On this page you can find statistics about your parking areas.',
            revenue: 'Revenue',
            chooseParkingAreas: 'Choose parking areas',
            chooseRevenueType: 'Choose revenue type',
            total: 'Total',
            bookings: 'Bookings',
            permits: 'Permits',
            controlfees: 'Control fees',
            subletbookings: 'Sublet bookings',
            charging: 'Charging',
            chooseInterval: 'Choose interval',
          },
          payoutStatus: {
            pending: 'Pending',
            processing: 'Processing',
            processed: 'Paid',
            failed: 'Error',
            readytoprocess: 'Ready to process',
          },
          payoutTransactionType: {
            booking: 'Booking',
            permit: 'Parking permit',
            subletting: 'Subletting',
          },
          payoutsPage: {
            title: 'Payouts',
            description: 'On this page you can find a list of all your payouts. You can also see the status of each payout.',
            payouts: 'Payouts',
          },
          payoutsTable: {
            payoutDate: 'Payout date',
            noPayoutsFound: 'No payouts found',
            description: 'Description',
            period: 'For Period'
          },
          payoutDetailsPage: {
            title: 'Payout details',
            description: 'On this page you\'ll find the details for your payout with ID {{payoutExtId}}.',
            sum: 'Sum',
            payoutDate: 'Payout date',
            vat: 'VAT',
            status: 'Status',
            payoutLines: 'Payout lines',
            exportToCsv: 'Export to CSV',
            printSummary: 'Print summary',
            openCsvTitle: 'Open CSV',
            openCsvDescription: 'To open the CSV file, you need a program that can read CSV files, such as Excel or Numbers. In Numbers, you don\'t need to do anything special to open the file. In Excel, you need to select column A, then click on "Data" in the menu, choose "Text to Columns," select "Delimited," check the "Comma" option, and then click "OK."',
          },
          payoutDetailsTable: {
            type: 'Type',
            sum: 'Sum',
            amount: 'Amount',
            vat: 'VAT',
            transactionFee: 'Transaction fee',
            airParkFee: 'AirPark fee',
            propertyOwnerFee: 'Property owner fee',
            subletting: 'Subletting',
            paymentProvider: 'Payment provider',
            paymentAmount: 'Payment amount',
            totalVat: 'Total VAT',
            ownerAmount: 'Owner amount',
            subletterAmount: 'Subletter amount',
            payoutAmount: 'Payout amount',
            vatOnPayoutAmount: 'VAT on payout amount',
            paymentExtId: 'Payment ID',
          },
          controlFeesPage: {
            title: 'Control fees',
            description: 'On this page you can find a list of all your control fees. You can also see the status of each control fee.',
            videoInstructionsDescription: 'Watch our video instructions on how to issue control fees.',
            signsRequiredTitle: 'Valid signs required',
            signsRequiredDescription: 'You need to have valid signs – that meet the legal requirements – at the entrance to your parking area that informs the users that they are entering a controlled parking area. Contact AirPark if you are unsure or need to order these.',
            contactAirPark: 'Contact AirPark',
          },
          controlFeesTable: {
            createdAt: 'Reported date',
            status: 'Status',
            amount: 'Amount due',
            noControlFeesFound: 'No control fees found',
            pending: 'In review by AirPark',
            approved: 'Approved by AirPark',
            rejected: 'Rejected by AirPark',
            paid: 'Paid',
            overdue: 'Overdue',
            paymentDue: 'Payment due',
            unspecified: 'Unspecified',
            wrongSpot: 'Wrong spot',
            overdue: 'Overstayed',
            forbidden: 'Parking forbidden',
            wrongSpot: 'Wrong spot',
            outsideMarks: 'Outside parking marks',
            paymentDueDate: 'Payment due date',
            reason: 'Reason for control fee',
            unpaid: 'Unpaid',
            paymentStatus: 'Payment status',
            notes: 'Notes',
          },
          permitParkingSpotsPage: {
            title: 'Parking spots with permits',
            description: 'On this page you can find a list of all the parking spots that have permits.',
          },
          permitParkingSpotsTable: {
            permits: 'Permits',
            permit: 'permit',
            marketplacePermits: 'permits in marketplace',
            id: 'Id',
            locationSpecific: 'Specific location',
            number: 'Number',
            features: 'Features',
          },
          permitsModal: {
            id: 'Id',
            spotIdentifier: 'Spot',
            name: 'Name',
            notes: 'Notes',
            permits: 'Permits'
          },
          controlFeesSettingsPage: {
            title: 'Control fees settings',
            description: 'On this page you can manage the settings for control fees.',
            whoIsAllowedToIssueControlFees: 'Who is allowed to issue control fees?',
            noone: 'No one',
            communityMembers: 'Community members',
            everyone: 'Everyone',
            controlFeeAmount: 'Control fees amount',
            controlFeeSettingsUpdatedTitle: 'Updated',
            controlFeeSettingsUpdatedDescription: 'Control fee settings updated',
          },
          signsPage: {
            title: 'Signs',
            description: 'Make it easy for your parking customers to find their spot. On this page you can download signs for your parking area and print them.',
            alertTitle: 'Other signs',
            alertDescription: 'If you need official road signs to be able to issue control fees or require other signs, please contact'
          },
          parkingAreaSignsPdf: {
            getTheApp: 'Get the app',
            parkingArea: 'Parking area',
            area: 'Area'
          },
          payoutPrintPage: {
            title: 'Payout summary',
            subletting: 'Subletting',
            sublettingVat: 'Subletting, VAT',
            bookings: 'Bookings',
            bookingsVat: 'Bookings, VAT',
            permits: 'Permits',
            permitsVat: 'Permits, VAT',
            controlFees: 'Control fees',
            controlFeesVat: 'Control fees, VAT',
            totalExVat: 'Total, excl. VAT',
            total: 'Total',
            totalVat: 'Total, VAT',
            period: 'Period',
            reference: 'Our reference',
            address: 'Address',
            vatNumber: 'VAT number',
            organizationNumber: 'Org. number',
            payoutReference: 'Payout reference',
            payoutDate: 'Payout date',
            charging: 'Charging',
            chargingVat: 'Charging, VAT',
          },
          chargingPage: {
            title: 'Electric car charging',
            description: 'On this page you can configure charging for your parking spaces.',
            pricingTitle: 'Price for electric car charging',
            pricingDescription: 'Here you can configure prices for charging in your parking spaces.',
            addChargingStationTitle: 'Add electric car charger',
            addChargingStationDescription: 'Here you can add electric car chargers to your parking spaces.',
            priceForCharging: 'Price',
            priceForChargingTooltip: 'The price to be paid to charge the electric car.',
          },
          chargingStationPage: {
            addTitle: 'Add charging station',
            selectParkingSpot: 'Select parking spot',
            selectProvider: 'Select provider',
            selectParkingSpotWarning: 'Please select a parking spot where the charging station will be located.',
            selectedParkingSpot: '{{spot}} is selected',
            addSuccessTitle: 'Charging station added',
            addSuccessDescription: 'Charging station added successfully.',
            editTitle: 'Edit charging station',
            updatedSuccessTitle: 'Charging station updated',
            updatedSuccessDescription: 'Charging station updated successfully.',
          }, 
          waybler: {
            chargingStationId: 'Waybler charging station ID',
            chargingStationIdPlaceholder: 'Enter Waybler charging station ID',
          },
          chargingStationsTable: {
            id: 'Id',
            parkingSpotNumber: 'Parking spot number',
            chargingProvider: 'Charging provider',
            noChargingStationsFound: 'No charging stations found',
            chargingProviderExternalId: 'Charging provider external ID',
            removeChargingStationTitle: 'Remove charging station',
            removeChargingStationDescription: 'Are you sure you want to remove the charging station?',
            removedChargingStationTitle: 'Charging station removed',
            removedChargingStationDescription: 'Charging station removed successfully.',
            noChargingStations: 'No charging stations',
            charging: 'Charging',
            notCharging: 'Not charging',
            status: 'Status'
          },
          airPassPage: {
            title: 'AirPass',
            description: 'On this page you can find a list of all your AirPasses.',
          },
          airPassTable: {
            id: 'Id',
            description: 'Description',
            location: 'Location',
            noAirPassesFound: 'No AirPasses found',
          },
          editAirPassPage: {
            title: 'Edit AirPass',
            airPassDescriptionTitle: 'Description',
            airPassDescriptionPlaceholder: 'Enter AirPass description',
            editAirPassPosition: 'Edit AirPass position',
            updatedSuccessTitle: 'Updated',
            updatedSuccessDescription: 'AirPass updated successfully.',
          },
          customAgreementPage: {
            title: 'Custom agreement',
            description: 'On this page you can upload a custom agreement that will need to be accepted by the user when they sign up for a permit.',
            customAgreement: 'Custom agreement',
          },
          bookingsPage: {
            title: 'Bookings',
            description: 'On this page you can find a list of all your bookings for your parking area.',
            parkingSpot: 'Parking spot',
            startDate: 'Start date',
            endDate: 'End date',
            duration: 'Duration',
            paidAmount: 'Paid amount',
            registrationIdentifier: 'Registration identifier',
            expired: 'Expired',
            noResult: 'No bookings found',
          },
          acceptTermsAndConditionsPage: {
            title: 'Accept terms and conditions',
            description: 'To continue using AirPark, you need to accept the updated terms and conditions.',
            accept: 'Accept',
            privacyPolicy: 'Privacy policy',
            termsAndConditions: 'Terms and conditions',
          }
        }
      },

      /* SWEDISH */
      sv: {
        translation: {
          generic: {
            successTitle: 'Uppdaterat',
            successDescription: 'Uppdateringen lyckades',
            errorTitle: 'Något gick fel',
            errorDescription: 'Något gick fel, vänligen försök igen.',
            missingKeyReplacement: '...',
            yes: 'Ja',
            no: 'Nej',
            remove: 'Ta bort',
            close: 'Stäng',
            back: 'Tillbaka',
            edit: 'Redigera',
            cancel: 'Avbryt',
            ok: 'OK',
            save: 'Spara',
            update: 'Uppdatera',
            email: 'E-post',
            phone: 'Telefonnummer',
            search: 'Sök',
            airPark: 'AirPark',
            create: 'Skapa',
            missingFieldsTitle: 'Fält saknas',
            missingFieldsDescription: 'Ett eller flera fält saknar information eller är inte korrekt ifyllda',
            saving: 'Sparar',
            uploadImageFiles: "Klicka för att ladda upp bilder eller dra och släpp PNG, JPG, JPEG eller GIF",
            uploadDocumentFiles: "Klicka för att ladda upp filer eller dra och släpp GIF, JPG, JPEG PDF, eller PNG",
            warning: "Varning",
            areyousure: "Är du säker?",
            share: 'Dela',
            add: 'Lägg till',
            invite: 'Bjud in',
            signin: 'Logga in',
            signup: 'Registrera',
            signout: 'Logga ut',
            create: 'Skapa',
            status: 'Status',
            firstName: 'Förnamn',
            lastName: 'Efternamn',
            from: 'Från',
            to: 'Till',
            id: 'Id',
            amount: 'Summa',
            status: 'Status',
            choose: 'Välj',
            change: 'Ändra',
            revoke: 'Avsluta',
            videoInstructions: 'Videoinstruktioner',
            updated: 'Uppdaterat',
            updatedDescription: 'Uppdateratingen lyckades',
            download: 'Ladda ner',
            day: 'Dag',
            month: 'Månad',
            pending: 'Pågående',
            copy: 'Kopiera',
            copied: 'Kopierat',
            copiedDescription: 'Kopierat till urklipp',
            price: 'Pris',
            unit: 'Enhet',
            termsAndConditionsUrl: 'https://static.airpark.app/policy/2023/terms_and_conditions_sv-se.pdf',
            upload: 'Ladda upp',
          },
          error: {
            userAlreadyHaveAnIndividualOrganizationTitle: 'Du har redan en enskild organisation',
            userAlreadyHaveAnIndividualOrganizationDescription: 'Du kan inte ha flera enskilda organisationer'
          },
          legend: {
            totalAmount: 'Totala intäkter',
            bookingsAmount: 'Bokningar',
            permitsAmount: 'Parkeringstillstånd',
            controlFeesAmount: 'Kontrollavgifter',
            revenue: 'Intäkter',
          },
          dayShortNames: {
            monday: 'Må',
            tuesday: 'Ti',
            wednesday: 'On',
            thursday: 'To',
            friday: 'Fr',
            saturday: 'Lö',
            sunday: 'Sö'
          },
          daySingularNames: {
            monday: 'Måndag',
            tuesday: 'Tisdag',
            wednesday: 'Onsdag',
            thursday: 'Torsdag',
            friday: 'Fredag',
            saturday: 'Lördag',
            sunday: 'Söndag'
          },
          dayPluralNames: {
            monday: 'Måndagar',
            tuesday: 'Tisdagar',
            wednesday: 'Onsdagar',
            thursday: 'Torsdagar',
            friday: 'Fredagar',
            saturday: 'Lördagar',
            sunday: 'Söndagar'
          },
          navigation: {
            'parking-areas': 'Områden',
            'permits': 'Tillstånd',
            'queue': 'Kö',
            'community': 'Community',
            'control-fees': 'Kontrollavgifter',
            'marketplace': 'Marketplace',
            'parkingspots': 'Parkeringsplatser',
            'settings': 'Inställningar',
            'users': 'Användare',
            'connect-requests': 'Förfrågningar',
            'reports': 'Rapporter',
            'payouts': 'Utbetalningar',
            'revenue': 'Intäkter',
            'occupancy': 'Beläggning',
            'payout': 'Utbetalning',
            'signs': 'Skyltmaterial'
          },
          paging: {
            pageOfTotal: 'Sida {{page}} av {{total}}'
          },
          priceUnit: {
            hour: 'h',
            day: 'dag',
            month: 'mån',
            year: 'år',
            onetime: 'engång'
          },
          defaultLayout: {
            loading: 'Laddar...'
          },
          loginPage: {
            byLoggingInYouApprove: "Genom att logga in godkänner du",
            conditions: "villkoren",
            rememberMe: "Kom ihåg mig",
            mobileNumber: "Mobilnummer",
            selectCountryCode: "Välj land/region",
            search: "sök",
            loginWithMobileNumber: 'Logga in med mobilnummer',
            loginWithEmail: 'Logga in med e-post',
            emailIsNotValid: 'E-postadressen är inte giltig',
            noAccountSignup: 'Inget konto? Registrera dig',
            useTheApp: 'Visste du att du superenkelt kan hyra ut din parkeringsplats direkt i vår app? Ladda ner den här.',
          },
          verifySmsCodePage: {
            verifyCode: "Mata in kod",
            enterTheCodeThatWasSentTo: "Mata in koden som skickades till",
            didNotReceiveCode: "Fick du ingen kod? Försök igen",
            newCodeSentTitle: "Ny kod skickad",
            newCodeSentDescription: "En ny kod har skickats till telefonnummer",
            codeCouldNotBeVerifiedTitle: "Kod ej giltig",
            codeCouldNotBeVerifiedDescription: "Koden kunde inte verifieras. Försök igen."
          },
          registerPage: {
            beforeWeGetStarted: "Innan vi kör igång",
            description: "För att ta del av tjänsten och ge dig en fantastisk service behöver vi lite mer information om dig.",
            firstName: "Förnamn",
            surName: "Efternamn",
            country: 'Land',
            accept: 'Jag accepterar AirParks',
            termsAndConditions: 'allmänna villkor',
          },
          chooseOrganizationPage: {
            chooseOrganization: "Välj organisation",
            description: "Tillgängliga organisationer som du kan administrera parkeringar för.",
            createNewOrganization: "Skapa ny organisation",
            noOrganizationAvailableTitle: "Ingen organisation tillgänglig",
            noOrganizationAvailableDescription: "Ingen organisation tillgänglig. Skapa en ny organisation för att organisera dina parkeringar."
          },
          createOrganizationPage: {
            createOrganization: "Skapa organisation",
            description: "Skapa en organisation för att samla dina parkeringar. Det kan exempelvis vara ditt eget namn, namnet på din bostadsrättsförening eller företag.",
            nameMissingTitle: 'Namn saknas',
            nameMissingDescription: 'Fyll i all information för att skapa en organisation.',
            typeOfOrganization: 'Företag eller privatperson?',
            typeOfOrganizationTooltip: 'Ange vilken organisationstyp som du registrerar dig som. Är din organisation något annat än privatperson väljer du företag.',
            company: 'Företag',
            individual: 'Privatperson',
            country: 'Land',
            name: 'Namn',
            orgNbr: 'Organisationsnummer',
            socialSecurityNumber: 'Personnummer',
            address: 'Adress',
            zipCode: 'Postnummer',
            city: 'Stad',
            phone: 'Telefonnummer',
            email: 'E-post',
            youMustAcceptTermsAndConditionsTitle: 'Villkor ej accepterade',
            youMustAcceptTermsAndConditionsDescription: 'Du måste acceptera villkoren för att kunna skapa en organisation.',
            missingRequiredInfoTitle: 'Obligatorisk information saknas',
            missingRequiredInfoDescription: 'Du måste fylla i all nödvändig information.',
          },
          leftMenu: {
            start: "Start",
            areas: "Områden",
            reports: "Rapporter",
            users: "Användare",
            settings: "Inställningar",
            payouts: 'Utbetalningar',
            occupancy: 'Beläggning',
            revenue: 'Intäkter',
            payoutSettings: 'Utbetalningskonto',
            revenueSharing: 'Intäktsdelning',
            statistics: 'Statistik',
          },
          leftMenuParkingArea: {
            parking: "Parkering",
            permits: "Tillstånd",
            queue: "Kö",
            controlfees: "Kontrollavgifter",
            community: "Community",
            parkingSpots: "Parkeringsplatser",
            marketplace: "Marketplace",
            linkedCommunities: 'Länkade communities',
            controlFeesSettings: 'Inställningar',
            signs: 'Skyltmaterial',
            charging: 'Elbilsladdning',
            airPass: 'AirPass',
            customAgreement: 'Anpassat avtal',
            bookings: 'Bokningar',
          },
          getStartedWidgets: {
            welcome: "Välkommen till AirPark!",
            hello: "Hej {{name}}!",
            intro: "Detta är din startsida, efterhand som du aktiverar och hyr ut parkeringar kommer vi kunna mäta och ge dig intressant data här.",
            letsgo: "Nu kör vi!",
            createParkingAreasTitle: "Parkeringsområden",
            createParkingAreasDescription: "Sätt upp område där du kan lägga till en eller flera parkeringsplatser.",
            addBasicInformationTitle: "Grundinformation",
            addBasicInformationDescription: "Lägg upp information som exempelvis pris för parkeringen, beskrivning, bild, m.m.",
            addPriceRulesAndRewardTitle: "Prisregler och rewards",
            addPriceRulesAndRewardDescription: "Lägg in prisregler för att boosta dina inäkter. Du kan även skapa rewards för att belöna dina kunder när de parkerar!",
            toParkingAreas: 'Gå till parkeringsområden'
          },
          parkingAreas: {
            noAreasTitle: "Inga områden tillagda",
            noAreasDescription: "Börja med att lägg till ett område som innehåller en eller flera parkeringar.",
            areas: "Områden",
            addArea: "Lägg till område",
            pin: "Fäst",
            name: "Namn",
            parkings: "Parkeringar",
            noParkingAreas: "Inga parkeringsområden",
            videoInstructionsDescription: "Här kan du se en videoinstruktion om hur du lägger till ett parkeringsområde.",
          },
          topBar: {
            organizations: "Organisationer",
            addOrganization: 'Skapa ny organisation',
            logout: 'Logga ut'
          },
          createParkingArea: {
            name: "Namn",
            basics: "Områdesinformation",
            hour: "timme",
            day: "dag",
            oneTimeFee: "engångsavgift",
            position: "Position",
            basePrice: "Baspris",
            positionDescription: "Sök upp ditt område på kartan där parkeringsområdet är. Klicka sedan ut punkter på kartan för att ange ett område.",
            removePosition: "Ta bort område",
            detailsHeader: "Antal platser och features ",
            detailsDescription: "Uppge hur många platser som finns inomhus respektive utomhus samt vilka features det finns.",
            indoors: "Inomhus",
            outdoors: "Utomhus",
            chargingPost: "Laddbox",
            fenced: "Inhägnat",
            information: "Information",
            photo: "Foton",
            totalNumberOfParkingSpots: "Totalt {{totalNumberOfParkingSpots}} parkeringsplatser",
            confirm: 'Bekräfta parkeringsområdet',
            confirmDescription: 'Vi behöver dokumentation som styrker att du har rätt att lägga upp parkeringsområdet. Ladda upp dokument där AirPark kan verifiera detta. AirPark kontrollerar och återkommer.',
            address: 'Adress',
            noAddress: 'Ange ett område på kartan först genom att klicka ut punkter',
            changeAddress: 'Inte rätt adress? Skriv in adressen manuellt',
            city: 'Stad',
            zipCode: 'Postnummer',
            country: 'Land',
            search: 'Sök...',
            notnumbered: 'Ej numrerade',
            numbered: 'Numrerade',
            add: 'Lägg till',
            numberedTitle: 'Är parkeringsplatserna numrerade?',
            features: 'Egenskaper',
            from: 'Från',
            to: 'Till',
            parkingSpots: 'Parkeringsplatser',
            addParkingSpots: 'Lägg till parkeringsplatser',
            parkingAreaDetailsTitle: 'Beskrivning',
            parkingAreaDetailsDescription: 'Om det finns specifik information om parkeringsområdet eller bild så ange det här.',
            creatingParkingArea: 'Lägger upp...',
            inReviewTitle: '{{name}} inskickat för godkännande',
            inReviewDescription: 'AirPark kommer nu att titta på inskickad dokumentation och ditt parkeringsområde. Under tiden kan du göra fler inställningar såsom att sätta upp prisregler och ställa in för vem parkeringsområdet är tillgängligt för.',
            successTitle: '{{name}} upplagt',
            successDescription: 'I nästa läge kan du göra fler inställningar såsom att sätta upp prisregler och ställa in för vem parkeringsområdet är tillgängligt för.',
            amount: 'Antal',
            somethingWentWrongTitle: 'Något gick fel',
            somethingWentWrongDescription: 'Något gick fel när parkeringsområdet skulle skapas. Vänligen försök igen. Kontakta AirPark support om problemet kvarstår.',
            fileNotValidTitle: 'Fil ej giltig',
            fileNotValidDescription: 'Filen är inte giltig. Vänligen använd en PNG, JPG, JPEG eller GIF med en storlek på max 10MB.'
          },
          parkingAreaMap: {
            removeArea: 'Ta bort område'
          },
          parkingArea: {
            edit: 'Redigera',
            description: 'Beskrivning',
            noDescription: 'Ingen beskrivning för {{name}}',
            free: 'gratis',
            add: 'Lägg till',
            yes: 'Ja',
            no: 'Nej',
            downloadSigns: 'Ladda ner skyltar',
            notNumbered: 'Ej numrerad',
            features: 'Egenskaper',
            locationSpecific: 'Platsspecifik',
            indoors: 'Inomhus',
            outdoors: 'Utomhus',
            fenced: 'Inhägnat',
            hasChargingPost: 'Laddbox',
            id: 'Id',
            number: 'Nummer',
            parkings: 'Parkeringsplatser',
            awaitingApprovalTitle: '{{name}} granskas',
            awaitingApprovalDescription: '{{name}} granskas just nu av AirPark och väntar på godkännande. Under tiden kan du fylla på med parkeringar, prisregler osv. Området kommer inte dyka upp i appen förrän AirPark godkänt inskickat underlag.',
            availability: 'Pris & Tillgänglighet',
            outsideAvailabilityHours: 'Övrig tid',
            updatedTitle: 'Uppdaterat',
            updatedDescription: 'Parkeringsområdet är nu uppdaterat',
            parkingNotAllowed: 'Parkering ej tillåten',
            parkingFree: 'Gratis parkering',
            startDate: 'Startdatum',
            endDate: 'Slutdatum',
            period: 'Period',
            days: 'Dagar',
            time: 'Tider',
            parkingSpots: 'Platser',
            availableFor: 'Tillgänglig för',
            price: 'Pris',
            allDays: 'Alla',
            public: 'Alla',
            allParkingSpots: 'Alla',
            specificDays: 'Specifika dagar',
            community: 'Community',
            specificSpots: 'Specifika platser',
            removePriceRuleSetTitle: 'Ta bort?',
            removePriceRuleSetDescription: 'Är du säker på att du vill ta bort pris- & tillgänglighetsraden?',
            removedPriceRuleSetTitle: 'Borttaget',
            removedPriceRuleSetDescription: 'Tog bort pris & tillgänglighet',
            missingPriceRuleSets: 'Om du vill att kunder ska kunna boka parkeringar i AirPark appen behöver du lägga till pris och tillgänglighet',
            removedParkingSpotTitle: 'Borttagen',
            removedParkingSpotDescription: 'Tog bort parkeringsplats',
            removeParkingSpotTitle: 'Ta bort?',
            removeParkingSpotDescription: 'Är du säker på att du vill ta bort parkeringsplatsen?',
            approvedTitle: 'Godkänt',
            approvedDescription: 'Parkeringsområdet är nu godkänt av AirPark. Lägg till prisregler och tillgänglighet för att användare ska kunna boka i AirPark appen.',
            asDefinedByPeriod: 'Enligt perioden',
            removeParkingAreaTitle: 'Ta bort område',
            removeParkingAreaDescription: 'Är du säker på att du vill ta bort parkeringsområdet? Detta går inte att ångra!',
            ongoing: 'tillsvidare',
            removedParkingAreaTitle: 'Borttaget',
            removedParkingAreaDescription: 'Området är nu borttaget',
            videoInstructionsDescription: 'Se vår video om hur du ställer in pris- och tillgänglighetsregler',
          },
          addPriceRuleSet: {
            title: 'Lägg till pris & tillgänglighet',
            description: 'Beskrivning',
            from: 'Från',
            to: 'Till',
            pickParkingSpots: 'Välj parkering',
            all: 'Alla',
            specificSpots: 'Specifika platser',
            selectedNumberOfSpots: '{{number}} platser valda',
            onWeekdays: 'På veckodagar',
            public: 'Alla',
            community: 'Community',
            availability: 'Dagar',
            betweenDates: 'Alla',
            specificDays: 'Specifika dagar',
            save: 'Spara',
            between: 'Mellan',
            errorTitle: 'Något gick fel',
            errorName: 'Du måste lägga till beskrivning/namn',
            errorParkingSpots: 'Inga parkeringar valda',
            errorDateRange: 'Från datum får inte vara större än till datum',
            errorPrice: 'Pris får inte vara tomt eller 0',
            priceRuleSetUpdatedTitle: 'Uppdaterat',
            priceRuleSetUpdatedDescription: '{{name}} är nu uppdaterat',
            priceRuleSetAddedTitle: 'Tillagt',
            priceRuleSetAddedDescription: '{{name}} är nu tillagt för parkeringsområdet',
            price: 'Pris',
            availableFor: 'Tillgänglig för',
            limitNumberOfHoursParkingIsAllowed: 'Begränsa hur länge man får parkera?',
            maxParkingTimeInHours: 'Max parkeringstid i timmar',
            tooltipAvailability: 'För alla dagar gäller pris och tillgänglighet för hela perioden oavsett veckodag. Välj specifika dagar om pris och tillgänglighet ska gälla för specifika dagar och tider inom tidsperioden. Exempelvis: Mån-Fre 18:00-21:00.',
            tooltipDescription: 'Ge pris och tillgängligheten ett namn eller beskrivning. Exempelvis: Mån-Fre 18:00-21:00 10kr/h. Detta syns enbart i portalen och inte i AirPark appen.',
            editTitle: 'Redigera pris & tillgänglighet',
            noEndDate: 'Tillsvidare',
            chooseCommunity: 'Välj community',
            noCommunitiesTitle: 'Inga communities',
            noCommunitiesDescription: 'Inga communities tillgängliga för parkeringsområdet. Skapa ett community på Community-sidan.',
            recurring: 'Upprepa',
            recurringTo: 'Upprepa till',
            between: 'Mellan',
            and: 'och',
          },
          selectParkingSpotsModal: {
            selectParkingSpots: 'Välj parkeringsplatser',
            selectParkingSpot: 'Välj parkeringsplats',
            yes: 'Ja',
            no: 'Nej',
            notNumbered: 'Ej numrerad',
            features: 'Egenskaper',
            locationSpecific: 'Platsspecifik',
            id: 'Id',
            number: 'Nummer',
            numberOfParkingSpotsSelected: '{{number}} platser valda',
            noParkingSpotsAvailable: 'Inga parkeringsplatser tillgängliga'
          },
          editParkingSpotModal: {
            editParkingSpot: 'Redigera parkeringsplats',
            parkingSpotActive: 'Parkeringsplats tillgänglig',
            parkingSpotDisabled: 'Parkeringsplats otillgänglig',
            outdoors: 'Utomhus',
            indoors: 'Inomhus',
            fenced: 'Inhägnat',
            chargingPost: 'Laddbox',
            features: 'Egenskaper',
            description: 'Beskrivning',
            positionSpecific: 'Numrerad',
            position: 'Position',
            positionDescription: 'Om parkeringsplatsen är platsspecifik kan du ange en exakt position. Placera ut parkeringsplatsens pin genom att klicka på en position på kartan.',
            number: 'Nummer',
            removePin: 'Ta bort pin',
            parkingSpotUpdatedTitle: 'Uppdaterad',
            parkingSpotUpdatedDescription: 'Parkeringsplatsen är uppdaterad'
          },
          addParkingSpotsModal: {
            title: 'Lägg till parkeringsplatser',
            addedParkingSpotsTitle: 'Tillagda',
            addedParkingSpotsDescription: 'Parkeringsplatserna tillagda på parkeringsområdet'
          },
          editParkingArea: {
            approvalRequiredTitle: 'Godkännande kan krävas',
            approvalRequiredDescription: 'Om parkeringsområdets position ändras krävs det ett godkännande från AirPark. Under granskningsprocessen kommer det inte vara möjligt att genomföra nya bokningar i AirPark appen eller att utfärda nya tillstånd.',
            description: 'Beskrivning',
            updatedTitle: 'Uppdaterat',
            updatedDescription: 'Parkeringsområdet är nu uppdaterat.',
            updatedDescriptionRequiresApproval: 'Parkeringsområdet är nu uppdaterat och hos AirPark för granskning.',
            uploadImages: 'Ladda upp bilder',
            parkingAreaHasNoImages: 'Parkeringsområdet har inga bilder. Ladda upp bilder och spara för att förbättra presentationen i AirPark appen.',
            fieldsMissingTitle: 'Fält saknas',
            fieldsMissingDescription: 'Ett eller flera fält är inte korrekt ifyllda',
          },
          createPermitPage: {
            createTitle: 'Skapa tillstånd',
            issueTitle: 'Utfärda tillstånd',
            phoneNumber: 'Mobilnummer till mottagaren',
            phoneNumberTooltip: 'Ett SMS med en länk till tillståndet kommer att skickas till mottagaren. Först när mottagaren har accepterat och genomfört en lyckad betalning för tillståndet blir det giltigt.',
            description: "Anteckning",
            descriptionTooltip: "Anteckningen syns bara i portalen. Mottagarens namn kommer att synas på tillståndet efter att det accepterats.",
            chooseParkingSpot: "Välj parkeringsplats",
            allParkingSpots: "Områdesparkering",
            specificParkingSpots: "Platsspecifik",
            period: 'Dagar',
            betweenDates: 'Alla',
            specificDays: 'Specifika dagar',
            tooltipPeriod: 'För alla dagar gäller tillståndet för hela den angivna perioden oavsett veckodag. Välj specifika dagar om tillståndet ska gälla för specifika dagar och tider inom tidsperioden. Exempelvis: Mån-Fre 18:00-21:00.',
            spotSelected: 'Plats vald',
            noSpotSelected: 'Ingen plats vald',
            from: 'Från',
            to: 'Till',
            onWeekdays: 'På veckodagar',
            between: 'Mellan',
            isSublettingAllowed: 'Får hyras ut i andrahand',
            canBeSublettedTo: 'Får hyras ut i andrahand till',
            isSublettingAllowedDescription: 'Välj om tillståndet kan hyras ut i andrahand. Tillståndet kan begränsas till att bara kunna hyras ut till de communities som parkeringsområdet är anslutet till.',
            isSublettingAllowedMarketplaceDescription: 'Välj om tillståndet kan hyras ut i andrahand. Om tillståndet enbart är tillgängligt på marketplace för communities som parkeringsområdet är anslutet till kommer det enbart att kunna hyras ut i andrahand till medlemmar i dessa communities.',
            everyone: 'Publikt',
            invoicedBy: 'Fakturering hanteras av',
            externally: 'Annan part',
            price: 'Pris',
            invoicedByTooltip: 'Välj hur fakturering av tillståndet ska hanteras. AirPark kan sköta fakturering av tillståndet mot slutkund och automatiskt uppdatera tillståndets giltighet. Annan part kan även sköta faktureringen men då behöver tillståndets giltighet hanteras manuellt.',
            invoicedExternallyTitle: 'Annan part',
            invoicedExternallyDescription: 'Faktureringen sköts av annan part. All administration av fakturering och hantering av tillståndets giltighet kopplat till genomförda betalningar behöver hanteras manuellt eller av annan part.',
            community: 'Communities',
            noEndDate: 'Tillsvidare',
            permitCreatedTitle: 'Tillstånd skapat',
            permitCreatedDescription: 'Tillstånd skapat och ett SMS har skickats till {{phone}} med en inbjudningslänk. När mottagaren har accepterat tillståndet kommer dennes namn att synas på tillståndet.',
            editTitle: 'Redigera tillstånd',
            permitUpdatedTitle: 'Uppdaterat',
            permitUpdatedDescription: 'Tillståndet är nu uppdaterat.',
            marketplaceAvailability: 'Tillgängligt på marketplace för',
            marketplacePermitCreatedTitle: 'Tillstånd skapat',
            marketplacePermitCreatedDescription: 'Tillståndet skapat och tillgängligt på marketplace.',
            marketplacePermit: 'Välj om tillståndet ska vara tillgängligt på marketplace att köpas av alla användare eller om det ska vara tillgängligt för medlemmar i communities som är anslutna till parkeringsområdet.',
            addUserToCommunity: 'Lägg till mottagare i communities',
            addUserToCommunityTooltip: 'Välj om mottagaren automatiskt ska läggas till i parkeringsområdets communities i samband med att parkeringstillståndet accepteras.',
            noCommunitiesFoundTitle: 'Inga communities hittades',
            noCommunitiesFoundDescription: 'Parkeringsområdet har inga communities. Gå till Community och skapa ett community för att kunna lägga till mottagare i communities.',
            userWillBeAddedToCommunitiesTitle: 'Communities',
            userWillBeAddedToCommunitiesDescription: 'Mottagaren kommer att läggas till i följande communities: {{communities}}',
            issue: 'Utfärda',
            parkingSpots: 'Parkeringsplatser'
          },
          permitsPage: {
            permitsPageTitle: 'Parkeringstillstånd',
            permitsPageDescription: 'Administera och utfärda parkeringstillstånd för parkeringsområdet eller enskilda parkeringsplatser. När ett parkeringstillstånd utfärdas får användaren ett SMS med en inbjudningslänk där tillståndet kan aktiveras.',
            addPermitTitle: 'Tillstånd',
            createPermit: 'Utfärda',
            expired: 'Utgångna',
            unpaid: 'Obetalda',
            activePermits: 'Aktiva tillstånd',
            parkingSpotsWithoutPermits: 'Parkeringplatser utan tillstånd',
            permitsInMarketplace: 'Tillgängliga på marketplace',
            videoInstructionsDescription: 'Se vår video om hur du utfärdar parkeringstillstånd',
          },
          inputPhoneNumber: {
            chooseCountryRegion: 'Välj land/region'
          },
          permitTable: {
            notAcceptedYet: 'Väntar på att accepteras',
            name: 'Namn',
            description: 'Anteckning',
            spotIdentifier: 'Platsnummer',
            timePeriod: 'Period',
            id: 'Id',
            status: 'Status',
            firstName: 'Förnamn',
            lastName: 'Efternamn',
            phoneNumber: 'Telefonnummer',
            licensePlateNumber: 'Registeringsnummer',
            period: 'Giltighetstid',
            time: 'Tid',
            price: 'Pris',
            subletting: 'Andrahandsuthyrning',
            duplicate: 'Duplicera',
            revoke: 'Avsluta',
            days: 'Dagar',
            parkingArea: 'Områdesparkering',
            noPermits: 'Inga tillstånd utfärdade. Klicka på Skapa för att utfärda ett nytt tillstånd.',
            noMarketplacePermits: 'Inga tillstånd tillgängliga på marketplace.',
            noPermitsFound: 'Inga tillstånd hittades.',
            active: 'Aktivt',
            pending: 'Inbjudan skickad',
            allowed: 'Tillåtet',
            notallowed: 'Ej tillåtet',
            invoicedBy: 'Faktureras av',
            thirdParty: 'Annan part',
            revokePermitTitle: 'Avsluta tillståndet?',
            revokePermitDescription: 'Tillståndet kommer att avslutas {{date}}. Om tillståndet har annan uppsägningstid kan du istället redigera tillståndet och sätta ett specifikt slutdatum.',
            revokedPermitTitle: 'Tillstånd avslutat',
            revokedPermitDescription: 'Tillståndet är nu avslutat och inte giltigt längre.',
            expired: 'Avslutat',
            ongoing: 'tillsvidare',
            marketplace: 'Tillgängligt på marketplace',
            availability: 'Tillgängligt för',
            community: 'Community',
            everyone: 'Publikt',
            deletePermitTitle: 'Ta bort?',
            deletePermitDescription: 'Detta kommer att ta bort tillståndet.',
            deletedTitle: 'Borttaget',
            deletedDescription: 'Tillstånd borttaget.'
          },
          dropDownMultiSelector: {
            filter: 'Filtrera'
          },
          verifyEmailPage: {
            verifyEmailTitle: 'Bekräfta e-postadress',
            verifyEmailDescription: 'Bekräfta din e-postadress genom att klicka på länken som skickades till {{email}}',
            emailCouldNotBeVerifiedTitle: 'Ej verifierad',
            emailCouldNotBeVerifiedDescription: 'E-postadressen har inte kunnat verifieras. Vänligen försök igen.',
            noVerificationEmailReceivedTitle: 'Inte fått e-postmeddelandet?',
            noVerificationEmailReceivedDescription: 'Kontrollera din skräppost-mapp först. Om du fortfarande inte hittar e-postmeddelandet kan du skicka ett nytt.',
            resendVerificationEmail: 'Skicka nytt e-postmeddelande',
            emailResentTitle: 'E-postmeddelande skickat',
            emailResentDescription: 'Ett nytt e-postmeddelande har skickats till {{email}}'
          },
          login: {
            loginInProgress: 'Loggar in...'
          },
          permitMarketplacePage: {
            title: 'Marketplace',
            create: 'Skapa',
            description: 'Lägg upp parkeringstillstånd som blir tillgängliga på marketplace i AirPark appen och kan köpas av användare. Parkeringstillståndet kan göras tillgängliga publikt eller enbart för de communities som parkeringsområdet är anslutet till.'
          },
          permitShare: {
            shareTitle: 'Dela tillstånd',
            shareDescription: 'Dela en länk till tillståndet. Skicka via e-post eller dela på sociala kanaler som exempelvis Facebook.',
            createdTitle: 'Tillstånd skapat',
            createdDescription: 'Tillståndet är skapat och tillgängligt på marketplace i AirPark appen. Det går även att dela tillståndet via e-post eller sociala kanaler som exempelvis Facebook med länken nedan.',
            updatedTitle: 'Tillstånd uppdaterat',
            updatedDescription: 'Tillståndet är uppdaterat och tillgängligt på marketplace i AirPark appen. Det går även att dela tillståndet via e-post eller sociala kanaler som exempelvis Facebook med länken nedan.',
            copyLink: 'Kopiera länk till urklipp',
            linkCopiedTitle: 'Kopierad',
            linkCopiedDescription: 'Länken är kopierad till urklipp'
          },
          settingsPage: {
            title: 'Inställningar',
            description: 'Hantera inställningarna för din organisation.',
            name: 'Namn',
            organizationNumber: 'Organisations- eller personnummer',
            address: 'Adress',
            zipCode: 'Postnummer',
            city: 'Stad',
            country: 'Land',
            phoneNumber: 'Telefonnummer',
            email: 'E-post',
            organizationUpdatedTitle: 'Uppdaterat',
            organizationUpdatedDescription: 'Organisationen är nu uppdaterad',
            vatPercentage: 'Moms i procent',
            selfBilling: 'Självfakturering',
            invoiceEmail: 'E-post för fakturor',
            selfBillingTooltip: 'Självfakturering innebär att du själv måste redovisa moms till Skatteverket. Om du inte väljer självfakturering redovisar AirPark hela momsen. Observera att självfakturering enbart är tillgängligt för momsregisterade bolag.',
            signAndSave: 'Signera och spara',
            taxIdentificationNumber: 'Momsregistreringsnummer',
            sign: 'Signera',
          },
          payoutSettingsPage: {
            stripeTitle: 'Utbetalningskonto',
            stripeDescription: 'AirPark använder den globala betalningsleverantören Stripe för utbetalningar.',
            stripeNotConfiguredTitle: 'Stripe är inte konfigurerat',
            stripeNotConfiguredDescription: 'Stripe är inte konfigurerat för din organisation. För att kunna ta emot utbetalningar behöver Stripe konfigureras.',
            configureStripe: 'Konfigurera Stripe',
            payoutDayOfMonthAndMinimumValueTitle: 'Utbetalningsdag och minimibelopp',
            payoutDayOfMonthAndMinimumValueDescription: 'Vilken dag i månaden utbetalningar görs och minimibeloppet för utbetalning.',
            minimumPayoutValueErrorTitle: 'Minimibeloppet är för lågt',
            minimumPayoutValueErrorDescription: 'Minimibeloppet för utbetalning måste vara minst 300.',
            stripeConfiguredTitle: 'Stripe konfigurerat',
            stripeConfiguredDescription: 'Stripe är konfigurerat och kopplat till ditt AirPark-konto. Din organisation kan nu ta emot betalningar.',
            payoutDayOfMonth: 'Utbetalningsdag',
            minimumPayoutValue: 'Minimibelopp för utbetalning',
            minimumPayoutValueTooltip: 'Minimibeloppet för utbetalning är det lägsta beloppet som kan utbetalas. Om det totala intäktsbeloppet för en månad är lägre än minimibeloppet för utbetalning kommer intäkterna att samlas ihop till nästa månad.',
            dac7title: 'DAC7',
            dac7description: 'För att AirPark ska kunna genomföra utbetalningar av dina intäkter är det nödvändigt att du fyller i de ovanstående uppgifterna.\n\nVi samlar in dessa uppgifter för att säkerställa att vi följer DAC7, den nya EU-lagen som trädde i kraft den 1 januari 2023 och som kräver att vi rapporterar intäkter från parkeringsuthyrning till Skatteverket.\n\nOm du önskar aktivera självfakturering, behöver du även ange ditt momsregistreringsnummer.\n\nGenom att fylla i dessa uppgifter och signera med BankID, försäkrar du oss om att informationen är korrekt och aktuell.',
            sign: 'Signera',
            dac7NotSignedTitle: 'Ej signerat',
            dac7NotSignedDescription: 'Uppgifterna är inte signerade med BankID. För att kunna ta emot utbetalningar och för självfakturering krävs att uppgifterna har blivit signerade.',
            dac7SignedTitle: 'Signerat',
            dac7SignedDescription: 'Uppgifterna är signerade med BankID.',
          },
          revenueSharingSettingsPage: {
            revenueSharingTitle: 'Intäktsdelning',
            revenueSharingDescription: 'Konfigurera hur intäktsdelningen ska fungera för andrahandsuthyrning för din organisation. Observera att intäktsfördelningen är efter AirParks avgifter.',
            revenueSharingSublettingTitle: 'Andrahandsuthyrning',
            forYou: 'Till dig',
            subletter: 'Uthyraren',
            shareIsNotValidTitle: 'Intäktsdelningen är inte giltig',
            shareIsNotValidDescription: 'Intäktsdelningen är inte giltig. Observera att summan av andelar för alla roller måste vara 100%.',
            
          },
          usersPage: {
            title: 'Hantera användare',
            description: 'Hantera användare som ska ha tillgång till din organisation. En portaladministratör kan se och administrera allt i er portal, en andrahandsuthyrare kan ange er organisation som mottagare av utbetalningar vid andrahandsuthyrning.',
            users: 'Användare',
            portalAdministrators: "Portaladministratörer",
            sublessors: "Andrahandsuthyrare",
            invites: 'Väntande inbjudningar',
          },
          usersTable: {
            firstName: 'Förnamn',
            lastName: 'Efternamn',
            email: 'E-post',
            phone: 'Telefonnummer',
            removeUserTitle: 'Ta bort användare?',
            removeUserDescription: 'Är du säker på att du till ta bort användaren? Användaren förlorar all behörighet till organisationen.',
            removedUserTitle: 'Användare borttagen',
            removedUserDescription: 'Användaren har nu blivit borttagen från organisationen',
            contact: 'Kontakt',
            invitationStatus: "Status",
            sent: "Skickat",
            accepted: "Accepterad",
            accessLevel: "Åtkomstnivå",
            sublessor: "Andrahandsuthyrare",
            portalAdmin: "Portal admin",
          },
          invitesTable: {
            phone: 'Telefonnummer',
            status: 'Status',
            issuedBy: 'Utfärdat av',
            contact: 'Kontakt',
            noInvites: 'Inga väntande inbjudningar',
            deleteInviteTitle: 'Ta bort inbjudan?',
            deleteInviteDescription: 'Är du säker på att du vill ta bort inbjudan? Detta kan inte ångras.',
            removedInviteTitle: 'Inbjudan borttagen',
            removedInviteDescription: 'Inbjudan har nu blivit borttagen',
            sentAt: 'Skickad',
          },
          inviteUserModal: {
            title: 'Bjud in användare',
            descriptionPortalAdmin: 'Skicka en inbjudan till användaren du vill bjuda in som portaladministratör.',
            descriptionSublessor: 'Skicka en inbjudan till användaren du vill bjuda in som andrahansuthyrare.',
            userInvitedTitle: 'Användaren inbjuden',
            userInvitedDescription: 'Användaren har blivit inbjuden till din organisation.',
            portalAdmin: "Portal admin",
            sublessor: "Andrahandsuthyrare",
          },
          inviteSublessorModal: {
            userInvitedTitle: 'Användare inbjuden',
            userInvitedDescription: 'Användaren har blivit inbjuden till din organisation som andrahandsuthyrare.'
          },
          organizationInvitationPage: {
            youveBeenInvitedTitle: 'Du har blivit inbjuden',
            youveBeenInvitedDescription: 'Du har blivit inbjuden att gå med i {{organizationName}} organisationen.',
            useEmail: 'Logga in eller registrera dig med e-postadressen',
            acceptInvite: 'Acceptera inbjudan',
            invitationNotFoundTitle: 'Hittades inte',
            invitationNotFoundDescription: 'Tyvärr, inbjudan kan inte hittas.',
            emailDoesNotMatchTitle: 'Fel e-post',
            emailDoesNotMatchDescription: 'E-postadressen du har loggat in med stämmer inte överens med e-postadressen som inbjudan skickades till.',
            emailNotVerifiedTitle: 'Inte verifierad',
            emailNotVerifiedDescription: 'Du behöver verifiera din e-post innan du kan acceptera inbjudan.'
          },
          communityPage: {
            title: 'Community',
            description: 'Communities kan skapas för parkeringsområdet och användas för att styra vilka priser, tillgängligheter och tillstånd som är giltiga för vem. Dessa communities kan sedan kopplas samman med andra communities för att utöka tillgängligheten för dina community-medlemmar.',
            communitiesTitle: 'Mina communities',
            addExistingCommunity: 'Lägg till befintligt community',
            videoInstructionsDescription: 'Se vår video om vad communities är och hur du använder dem.',
          },
          createCommunityPage: {
            title: 'Skapa community',
            name: 'Namn',
            description: 'Beskrivning',
            photos: 'Ladda upp bild',
            photosTooltip: 'Ladda upp en bild som representerar ditt community. Det går bara att ladda upp en bild.'
          },
          communitiesTable: {
            name: 'Namn',
            numberOfMembers: 'Antal medlemmar',
            description: 'Beskrivning',
            noCommunities: 'Inga communities',
            manageMembers: 'Hantera medlemmar',
            editCommunity: 'Redigera community',
          },
          manageCommunityPage: {
            title: 'Redigera community',
            uploadPhotos: 'Ladda upp bild',
            photos: 'Bild',
            photosTooltip: 'Ladda upp en bild som representerar ditt community. Det går bara att ladda upp en bild.',
            noPhotos: 'Ingen bild har laddats upp för detta community',
            communityUpdatedTitle: 'Community uppdaterat',
            communityUpdatedDescription: 'Community är nu uppdaterat'
          },
          manageCommunityMembersPage: {
            title: 'Hantera community-medlemmar',
            members: 'Medlemmar',
            inviteMember: 'Bjud in medlem',
            phoneNumber: 'Telefonnummer',
            pendingInvites: 'Väntande inbjudningar',
            phoneNumberMissingTitle: 'Telefonnummer saknas',
            phoneNumberMissingDescription: 'Ett telefonnummer krävs för att skicka en inbjudan',
            invitationSentTitle: 'Inbjudan skickad',
            invitationSentDescription: 'Inbjudan skickad till användaren',
            sendPhoneInvitationTitle: 'Skicka SMS-inbjudan',
            sendPhoneInvitationDescription: 'Skicka en inbjudan till användaren via SMS',
            createLinkInvitationTitle: 'Skapa inbjudningslänk',
            createLinkInvitationDescription: 'Skapa en inbjudan som kan skickas till användaren med exempelvis e-post. Länken kan användas flera gånger och är giltig tills den tas bort.',
            invitationLinkCreatedTitle: 'Inbjudningslänk skapad',
            invitationLinkCreatedDescription: 'Inbjudningslänken har nu skapats',
            noInvitationLinksCreated: 'Inga inbjudningslänkar har skapats',
          },
          communityMembersTable: {
            firstName: 'Förnamn',
            lastName: 'Efternamn',
            phoneNumber: 'Telefonnummer',
            noMembers: 'Inga medlemmar'
          },
          communityInviteLinksTable: {
            link: 'Länk',
            noLinks: 'Inga länkar',
          },
          connectedCommunitiesTable: {
            communityName: 'Mitt community',
            connectedCommunityName: 'Anslutet till',
            noConnectedCommunities: 'Inga anslutna communities',
            deleteCommunityConnectionTitle: 'Ta bort communities-anslutning',
            deleteCommunityConnectionDescription: 'Är du säker på att du vill ta bort communities-anslutningen?',
            removedCommunityConnectionTitle: 'Communities-anslutning borttagen',
            removedCommunityConnectionDescription: 'Communities-anslutningen har nu blivit borttagen'
          },
          createConnectCommunityRequestModal: {
            title: 'Anslut till community',
            stepsStart: 'För att hitta ett parkeringsområde och ansluta till ett community, följ dessa steg:',
            stepOne: 'Använd kartan för att hitta ett parkeringsområde och community som du vill ansluta till. Efter att du har valt ett område visas en lista över communities.',
            stepTwo: 'Välj det community som du vill ansluta till. Om du har fler än ett community måste du välja vilken av dina communities du vill skapa anslutningen för.',
            stepThree: 'Skicka förfrågan till community. Communityt kommer då att kunna acceptera eller avböja förfrågan.',
            stepsEnd: 'Om förfrågan accepteras kommer du att kunna se communities i listan över anslutna communities.',
            sendConnectRequest: 'Skicka anslutningsförfrågan',
            selectCommunity: 'Välj community att be om anslutning med',
            selectYourCommunity: 'Välj vilken av dina communities du vill begära anslutning för',
            noCommunitySelectedTitle: 'Ingen community vald',
            noCommunitySelectedDescription: 'Använd kartan för att hitta och välja ett parkeringsområde och community att ansluta till.',
            youCantConnectWithYourOwnParkingAreaTitle: 'Det går inte',
            youCantConnectWithYourOwnParkingAreaDescription: 'Du kan inte ansluta till din eget parkeringsområde.',
            noCommunitiesCreatedForParkingAreaTitle: 'Inga communities',
            noCommunitiesCreatedForParkingAreaDescription: 'Det finns inga communities som skapats för ditt parkeringsområde. Skapa en community först och försök sedan igen.',
            connectRequestSentTitle: 'Förfrågan skickad',
            connectRequestSentDescription: 'Förfrågan skickad till community. Community kommer att kunna acceptera eller avböja förfrågan.',
          },
          communityConnectionsPage: {
            title: 'Sammanlänkade communities',
            description: 'Sammanlänkade communities kan användas för att utöka tillgängligheten för dina community-medlemmar.',
            sentRequests: 'Skickade förfrågningar',
            receivedRequests: 'Mottagna förfrågningar',
            connectedCommunitiesTitle: 'Sammanlänkade communities',
            connect: 'Skicka länkförfrågan',
            pendingRequests: 'Väntande förfrågningar',
          },
          sentRequestsTable: {
            myCommunity: 'Mitt community',
            sentToCommunity: 'Skickad till community',
            noPendingRequests: 'Inga väntande förfrågningar',
            deleteSentRequestTitle: 'Ta bort skickad förfrågan',
            deleteSentRequestDescription: 'Är du säker på att du vill ta bort den skickade förfrågan?',
            removedSentRequestTitle: 'Förfrågan raderad',
            removedSentRequestDescription: 'Den skickade förfrågan raderades framgångsrikt',
          },
          receivedRequestsTable: {
            receivedFromCommunity: 'Mottagen från community',
            myCommunity: 'Mitt community',
            noPendingRequests: 'Inga väntande förfrågningar',
            deleteReceivedRequestTitle: 'Ta bort mottagen förfrågan',
            deleteReceivedRequestDescription: 'Är du säker på att du vill ta bort den mottagna förfrågan?',
            removedReceivedRequestTitle: 'Förfrågan raderad',
            removedReceivedRequestDescription: 'Den mottagna förfrågan raderades framgångsrikt',
          },
          communityConnectRequestPage: {
            title: 'Community länkförfrågan',
            howDoesItWorkTitle: 'Hur fungerar en community-länk?',
            howDoesItWorkDescription: 'Om du accepterar den här inbjudan kommer ditt parkeringscommunity att ansluta sig till dett inbjudande communityt. Detta kommer att tillåta medlemmar i det inbjudande communityt att få tillgång till parkeringsområdena och parkeringsplatserna som är associerade med ditt communitys pris- och tillgänglighetsregler, och medlemmar i ditt community kommer att kunna få tillgång till den inbjudande communityns resurser.',
            acceptRequest: 'Acceptera anslutningsförfrågan',
            acceptedTitle: 'Förfrågan accepterad',
            acceptedDescription: 'Förfrågan accepterades framgångsrikt',
            notFoundTitle: 'Förfrågan hittades inte',
            notFoundDescription: 'Förfrågan hittades inte',
            communityWantsToConnect: '{{communityName}} vill länka samman med ditt community {{communityRecipientName}}.',
            noAccessToAcceptTitle: 'Ingen tillgång',
            noAccessToAcceptDescription: 'Du har inte tillgång att acceptera denna förfrågan.',
          },
          notificationsMenu: {
            title: 'Notiser',
            noNotifications: 'Inga notiser',
          },
          permitQueuePage: {
            title: 'Tillståndskö',
            description: 'På den här sidan hittar du en lista över användare som har begärt ett tillstånd för att parkera på ditt parkeringsområde. Du kan sedan godkänna eller avvisa deras begäran.',
            usersInQueue: 'Användare i kö',
          },
          permitQueueTable: {
            inQueueSince: 'I kö sedan',
            noUsersInQueue: 'Inga användare i kö',
            specialRequests: 'Önskemål',
            nobodyInQueue: 'Ingen i kö',
            issuePermit: 'Utfärda tillstånd',
          },
          dashboardPage: {
            greeting: 'Hej, {{name}}!',
            createParkingAreaTitle: 'Sätt upp parkeringsområde',
            createParkingAreaDescription: 'För att komma igång med AirPark behöver du först sätta upp ett parkeringsområde. Därefter kan du lägga till prisregler och parkeringstillstånd för området.',
            hereIsYourAirParkOverview: 'Här är en översikt över ditt AirPark.',
            bookingsLastTwentyFourHours: 'Bokningar det senaste dygnet',
            activeParkingPermits: 'Aktiva parkeringstillstånd',
            totalPayouts: 'Totala utbetalningar',
            pendingPayouts: 'Väntande utbetalningar',
            revenue: 'Dina intäkter',
            revenueDescription: 'Dina intäkter senaste 12 månader, efter transaktionsavgifter och AirParks avdrag',
            lastTwelveMonths: 'Senaste 12 månaderna',
            noRevenueYet: 'Inga intäkter ännu',
          },
          statisticsPage: {
            title: 'Statistik',
            description: 'På den här sidan hittar du statistik över dina bokningar och parkeringstillstånd.',
            revenue: 'Intäkter',
            chooseParkingAreas: 'Välj parkeringsområden',
            chooseRevenueType: 'Välj intäktskälla',
            total: 'Totalt',
            bookings: 'Bokningar',
            permits: 'Tillstånd',
            controlfees: 'Kontrollavgifter',
            subletbookings: 'Andrahandsuthyrningar',
            charging: 'Elbilsladdning',
            chooseInterval: 'Välj intervall',
          },
          payoutStatus: {
            pending: 'Pågående',
            processing: 'Under behandling',
            processed: 'Utbetald',
            failed: 'Fel',
            readytoprocess: 'Klar för behandling',
          },
          payoutTransactionType: {
            booking: 'Bokning',
            permit: 'Parkeringstillstånd',
            subletting: 'Andrahandsuthyrning',
          },
          payoutsPage: {
            title: 'Utbetalningar',
            description: 'På den här sidan hittar du en lista över alla utbetalningar som har gjorts till ditt konto.',
            payouts: 'Utbetalningar',
          },
          payoutsTable: {
            payoutDate: 'Utbetalningsdatum',
            noPayoutsFound: 'Inga utbetalningar hittades',
            description: 'Beskrivning',
            period: 'För period'
          },
          payoutDetailsPage: {
            title: 'Utbetalningsdetaljer',
            description: 'På den här sidan hittar du detaljer för din utbetalning med ID {{payoutExtId}}.',
            sum: 'Summa',
            payoutDate: 'Utbetalningsdatum',
            vat: 'Moms',
            status: 'Status',
            payoutLines: 'Utbetalningsrader',
            exportToCsv: 'Exportera till CSV',
            printSummary: 'Skriv ut sammanfattning',
            openCsvTitle: 'Hur öppnar jag en CSV-fil?',
            openCsvDescription: 'För att öppna CSV-filen behöver du ett program som kan läsa CSV-filer som exempelvis Excel eller Numbers. I Numbers behöver du inte göra något speciellt för att öppna filen. I Excel behöver du markera kolumn A, därefter klicka på Data i menyn, välja Text till kolumner, välja Avgränsad, kryssa i Komma och sedan klicka på OK.'
          },
          payoutDetailsTable: {
            type: 'Typ',
            sum: 'Summa',
            amount: 'Belopp',
            vat: 'Moms',
            transactionFee: 'Transaktionsavgift',
            airParkFee: 'AirPark avgift',
            propertyOwnerFee: 'Fastighetsägare avgift',
            subletting: 'Andrahandsuthyrning',
            paymentProvider: 'Betalningsleverantör',
            paymentAmount: 'Betalningsbelopp',
            totalVat: 'Total moms',
            ownerAmount: 'Ägarbelopp',
            subletterAmount: 'Uthyrarens belopp',
            payoutAmount: 'Utbetalningsbelopp',
            vatOnPayoutAmount: 'Moms på utbetalningsbelopp',
            paymentExtId: 'Betalnings-ID',
          },
          controlFeesPage: {
            title: 'Kontrollavgifter',
            description: 'På denna sida kan du hitta en lista över alla dina kontrollavgifter. Du kan också se statusen för varje kontrollavgift.',
            videoInstructionsDescription: 'Titta på vår video för instruktioner om hur man utfärdar kontrollavgifter.',
            signsRequiredTitle: 'Giltiga skyltar krävs',
            signsRequiredDescription: 'Du måste ha giltiga skyltar som uppfyller lagkraven vid entrén till din parkeringsplats som informerar användarna om att de kommer in i en kontrollerad parkeringsplats. Kontakta AirPark om du är osäker eller behöver beställa dessa.',
            contactAirPark: 'Kontakta AirPark',
          },
          controlFeesTable: {
            createdAt: 'Inrapporteringsdatum',
            status: 'Status',
            amount: 'Belopp som ska betalas',
            noControlFeesFound: 'Inga kontrollavgifter hittades',
            pending: 'Under granskning av AirPark',
            approved: 'Godkänd av AirPark',
            rejected: 'Avvisad av AirPark',
            paid: 'Betald',
            overdue: 'Förfallen',
            paymentDue: 'Betalning förfallen',
            unspecified: 'Ej angivet',
            wrongSpot: 'Fel plats',
            overstayed: 'Överskridit tiden',
            forbidden: 'Parkering förbjuden',
            wrongSpot: 'Fel plats',
            outsideMarks: 'Utanför parkeringsmarkeringar',
            paymentDueDate: 'Betalningsförfallodatum',
            reason: 'Anledning till avgift',
            unpaid: 'Obetald',
            paymentStatus: 'Betalningsstatus',
            notes: 'Anteckningar',
          },
          permitParkingSpotsPage: {
            title: 'Parkeringsplatser med tillstånd',
            description: 'På den här sidan kan du hitta en lista över alla parkeringsplatser som har tillstånd.',
          },
          permitParkingSpotsTable: {
            permits: 'Tillstånd',
            permit: 'tillstånd',
            marketplacePermits: 'tillstånd i marketplace',
            id: 'Id',
            locationSpecific: 'Specifik plats',
            number: 'Nummer',
            features: 'Funktioner',
          },
          permitsModal: {
            id: 'Id',
            spotIdentifier: 'Plats',
            name: 'Namn',
            notes: 'Anteckningar',
            permits: 'Tillstånd'
          },
          controlFeesSettingsPage: {
            title: 'Inställningar för kontrollavgifter',
            description: 'På den här sidan kan du konfigurera inställningar för kontrollavgifter.',
            whoIsAllowedToIssueControlFees: 'Vem får utfärda kontrollavgifter?',
            noone: 'Ingen',
            communityMembers: 'Community-medlemmar',
            everyone: 'Alla',
            controlFeeAmount: 'Kontrollavgiftsbelopp',
            controlFeeSettingsUpdatedTitle: 'Uppdaterat',
            controlFeeSettingsUpdatedDescription: 'Inställningarna för kontrollavgifter har uppdaterats.',
          },
          signsPage: {
            title: 'Skyltmaterial',
            description: 'Ladda hem skyltmaterial för ditt parkeringsområde.',
            alertTitle: 'Övriga skyltar',
            alertDescription: 'Om du behöver officiella vägskyltar för att utfärda kontrollavgifter, kontakta',
          },
          parkingAreaSignsPdf: {
            getTheApp: 'Boka i appen',
            parkingArea: 'Parkeringsområde',
            area: 'Område'
          },
          payoutPrintPage: {
            title: 'Utbetalningsunderlag',
            subletting: 'Andrahandsuthyrning',
            sublettingVat: 'Andrahandsuthyrning, moms',
            bookings: 'Bokningar',
            bookingsVat: 'Bokningar, moms',
            permits: 'Tillstånd',
            permitsVat: 'Tillstånd, moms',
            controlFees: 'Kontrollavgifter',
            controlFeesVat: 'Kontrollavgifter, moms',
            total: 'Totalt inkl. moms',
            totalExVat: 'Totalt exkl. moms',
            totalVat: 'Moms',
            period: 'Period',
            reference: 'Vår referens',
            address: 'Utbetalningsadress',
            vatNumber: 'Momsreg.nr',
            organizationNumber: 'Org.nr',
            payoutReference: 'Utbetalningsreferens',
            payoutDate: 'Utbetalningsdatum',
            charging: 'Elbilsladdning',
            chargingVat: 'Elbilsladdning, moms',
          },
          chargingPage: {
            title: 'Elbilsladdning',
            description: 'På den här sidan kan du konfigurera laddning för dina parkeringsplatser.',
            pricingTitle: 'Pris för elbilsladdning',
            pricingDescription: 'Här kan du konfigurera priser för laddning på dina parkeringsplatser.',
            addChargingStationTitle: 'Lägg till elbilsladdare',
            addChargingStationDescription: 'Här kan du lägga till elbilsladdare på dina parkeringsplatser.',
            priceForCharging: 'Pris',
            priceForChargingTooltip: 'Priset som ska betalas för att ladda elbilen.',
          },
          chargingStationPage: {
            addTitle: 'Lägg till elbilsladdare',
            selectParkingSpot: 'Välj parkeringsplats',
            selectProvider: 'Välj leverantör',
            selectParkingSpotWarning: 'Välj parkeringsplats som elbilsladdaren är tillgänglig på.',
            selectedParkingSpot: '{{spot}} vald',
            addSuccessTitle: 'Elbilsladdare tillagd',
            addSuccessDescription: 'Elbilsladdaren har lagts till.',
            editTitle: 'Redigera elbilsladdare',
            updatedSuccessTitle: 'Elbilsladdare uppdaterad',
            updatedSuccessDescription: 'Elbilsladdaren har uppdaterats.',
          }, 
          waybler: {
            chargingStationId: 'Waybler elbilsladdstations-ID',
            chargingStationIdPlaceholder: 'Ange Waybler elbilsladdstations-ID'
          },
          chargingStationsTable: {
            id: 'Id',
            parkingSpotNumber: 'Parkeringsplats',
            chargingProvider: 'Leverantör',
            noChargingStationsFound: 'Inga elbilsladdare hittades',
            chargingProviderExternalId: 'Leverantörens elbilsladdstations-ID',
            removeChargingStationTitle: 'Ta bort elbilsladdare',
            removeChargingStationDescription: 'Är du säker på att du vill ta bort elbilsladdaren?',
            removedChargingStationTitle: 'Elbilsladdare borttagen',
            removedChargingStationDescription: 'Elbilsladdaren har tagits bort.',
            noChargingStations: 'Inga elbilsladdare hittades',
            charging: 'Laddar',
            notCharging: 'Laddar inte',
            status: 'Status'
          },
          airPassPage: {
            title: 'AirPass',
            description: 'På den här sidan kan du se dina AirPass för parkeringsområdet. Du kan även markera var dina AirPass är placerade.',
          },
          airPassTable: {
            id: 'Id',
            description: 'Beskrivning',
            location: 'GPS position',
            noAirPassesFound: 'Inga AirPass hittades',
          },
          editAirPassPage: {
            title: 'Redigera AirPass',
            airPassDescriptionTitle: 'Beskrivning',
            airPassDescriptionPlaceholder: 'Ange beskrivning',
            editAirPassPosition: 'Ange AirPass position',
            updatedSuccessTitle: 'Uppdaterad',
            updatedSuccessDescription: 'AirPass har uppdaterats.',
          },
          customAgreementPage: {
            title: 'Anpassat avtal',
            description: 'På den här sidan kan du ladda upp ett anpassat avtal som dina kunder måste godkänna innan de kan acceptera ett parkeringstillstånd.',
            customAgreement: 'Anpassat avtal',
          },
          bookingsPage: {
            title: 'Bokningar',
            description: 'På den här sidan kan du se en lista över alla bokningar som har gjorts för ditt parkeringsområde.',
            parkingSpot: 'Parkeringsplats',
            startDate: 'Startdatum',
            endDate: 'Slutdatum',
            duration: 'Varaktighet',
            paidAmount: 'Betalt belopp',
            registrationIdentifier: 'Registreringsnummer',
            expired: 'Avslutade',
            noResult: 'Inga bokningar hittades',
          },
          acceptTermsAndConditionsPage: {
            title: 'Godkänn villkor',
            description: 'För att fortsätta kunna använda AirPark behöver du godkänna våra uppdaterade allmänna villkor och integritetspolicy.',
            accept: 'Godkänn',
            privacyPolicy: 'Integritetspolicy',
            termsAndConditions: 'Allmänna villkor',
          }
        },
      }
    }
  });

export default i18n;