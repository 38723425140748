import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GarageIcon } from 'assets/icons/ico_garage_black.svg';
import { ReactComponent as ElectricityIcon } from 'assets/icons/ico_electricity_black.svg';
import { ReactComponent as FenceIcon } from 'assets/icons/ico_fence_black.svg';
import { ReactComponent as SunIcon } from 'assets/icons/ico_sun.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';

import Paging from 'components/common/paging';
import ButtonText from 'components/common/buttonText';
import CheckBox from 'components/common/checkbox/checkbox';

const SelectParkingSpotsModal = ({ display, setDisplay, selectedParkingSpots, setSelectedParkingSpots, parkingSpots, isSingleSelectionOnly }) => {

    const { t } = useTranslation();
    const handleOverlayOnClick = (e) => {
        closeModal();
        e.stopPropagation();
    }

    const closeModal = () => {
        document.body.classList.remove('overflow-hidden');
        setDisplay(false);
    }

    const itemsPerPage = 8;
    const [localParkingSpots, setLocalParkingSpots] = useState(parkingSpots);
    const [isAllChecked, setIsAllChecked] = useState(true);
    const [parkingSpotPages, setParkingSpotPages] = useState();
    const [currentParkingSpotPage, setCurrentParkingSpotPage] = useState(0);

    useEffect(() => {
        if (selectedParkingSpots && localParkingSpots) {
            const parkingSpotsWithCheckedStatus = localParkingSpots.map((parkingSpot, i) => {
                const parkingSpotResult = selectedParkingSpots.find(({ parkingSpotExtId }) => parkingSpotExtId === parkingSpot.parkingSpotExtId);

                if (parkingSpotResult) {
                    parkingSpot.isChecked = true;
                } else {
                    parkingSpot.isChecked = false;
                }

                return parkingSpot;
            });

            setLocalParkingSpots(parkingSpotsWithCheckedStatus);
        }
    }, [selectedParkingSpots]);

    useEffect(() => {
        if (localParkingSpots.length === selectedParkingSpots.length) {
            setIsAllChecked(true);
        } else {
            setIsAllChecked(false);
        }
    }, [selectedParkingSpots]);

    useEffect(() => {
        if (localParkingSpots) {
            setParkingSpotPages(Math.ceil(localParkingSpots.length / itemsPerPage));
        }
    }, [localParkingSpots]);

    useEffect(() => {
        if (display) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [display]);

    const handleParkingSpotItemSelect = (parkingSpot) => {
        if (parkingSpot.isChecked) {
            /* It was checked previously, remove */
            const index = selectedParkingSpots.findIndex(({ parkingSpotExtId }) => parkingSpotExtId === parkingSpot.parkingSpotExtId);
            selectedParkingSpots.splice(index, 1);
            setSelectedParkingSpots([...selectedParkingSpots]);
        } else {
            /* Else add */
            const result = selectedParkingSpots.find(({ parkingSpotExtId }) => parkingSpotExtId === parkingSpot.parkingSpotExtId);

            if (!result) {
                if (!isSingleSelectionOnly) {
                    selectedParkingSpots.push(parkingSpot);
                    setSelectedParkingSpots([...selectedParkingSpots]);
                } else {
                    setSelectedParkingSpots([parkingSpot]);
                }
            }
        }
    }

    const handleSelectAllParkingSpots = () => {
        if (localParkingSpots.length === selectedParkingSpots.length) {
            setSelectedParkingSpots([]);
        } else {
            if (!isSingleSelectionOnly) {
                setSelectedParkingSpots(localParkingSpots);
            }
        }
    }

    if (!display) {
        return (null);
    }

    return (
        <>
            {/* Overlay */}
            <div className="fixed inset-0 z-50 bg-black bg-opacity-60">
            </div>
            {/* Modal */}
            <div onClick={(e) => handleOverlayOnClick(e)} className="fixed inset-0 z-50 overflow-y-scroll overflow-x-hidden px-6">
                <div onClick={(e) => e.stopPropagation()} className="flex mr-auto ml-auto mt-6 mb-6 flex-col max-w-[1140px] p-6 w-full justify-center rounded-xl overflow-y-hidden items-center bg-white">
                    <div className="flex w-full justify-end mb-6">
                        <button className="h-4" onClick={() => { closeModal(); }}>
                            <CloseIcon className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="flex flex-col rounded-xl shadow p-6 w-full">
                        <h2 className="text-xl font-medium mb-6">{isSingleSelectionOnly ? t('selectParkingSpotsModal.selectParkingSpot') : t('selectParkingSpotsModal.selectParkingSpots')}</h2>
                        <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="text-left h-11 bg-airpark-gray-300">
                                        <th className="pl-6">
                                            <CheckBox isChecked={isAllChecked} onClick={() => { handleSelectAllParkingSpots(); }} />
                                        </th>
                                        <th className="pl-6">{t('selectParkingSpotsModal.id')}</th>
                                        <th className="pl-6">{t('selectParkingSpotsModal.locationSpecific')}</th>
                                        <th className="pl-6">{t('selectParkingSpotsModal.number')}</th>
                                        <th className="pl-6 pr-6">{t('selectParkingSpotsModal.features')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {localParkingSpots && localParkingSpots.length > 0 && localParkingSpots.slice(currentParkingSpotPage * itemsPerPage, ((currentParkingSpotPage * itemsPerPage) + itemsPerPage)).map((ps, i) => {
                                        return (
                                            <tr key={i} className="h-16 border-b-[1px] last:border-b-[0px]">
                                                <td className="pl-6">
                                                    <CheckBox isChecked={ps.isChecked ?? false} onClick={() => { handleParkingSpotItemSelect(ps); }} />
                                                </td>
                                                <td className="pl-6">{ps.parkingSpotExtId}</td>
                                                {ps.isNumbered &&
                                                    <td className="pl-6">{t('selectParkingSpotsModal.yes')}</td>
                                                }
                                                {!ps.isNumbered &&
                                                    <td className="pl-6">{t('selectParkingSpotsModal.no')}</td>
                                                }
                                                {ps.isNumbered &&
                                                    <td className="pl-6">{ps.spotIdentifier}</td>
                                                }
                                                {!ps.isNumbered &&
                                                    <td className="pl-6">{t('selectParkingSpotsModal.notNumbered')}</td>
                                                }
                                                <td className="pl-6 pr-6">
                                                    <div className="flex">
                                                        {ps.isIndoor &&
                                                            <GarageIcon className="h-6 w-6" />
                                                        }
                                                        {!ps.isIndoor &&
                                                            <SunIcon className="h-6 w-6" />
                                                        }
                                                        {ps.hasChargingPost &&
                                                            <ElectricityIcon className="h-6 w-6 ml-2" />
                                                        }
                                                        {ps.isFenced &&
                                                            <FenceIcon className="h-6 w-6 ml-2" />
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                    {parkingSpots && parkingSpots.length < 1 &&
                                        <tr className="h-16">
                                            <td className="text-center" colSpan={5}>
                                                <div className="flex pl-6 gap-3 flex-row items-center justify-left">
                                                    <InfoIcon className="h-6 w-6" />
                                                    <span>{t('selectParkingSpotsModal.noParkingSpotsAvailable')}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <Paging totalNumberOfPages={parkingSpotPages} currentPage={currentParkingSpotPage} setCurrentPage={setCurrentParkingSpotPage} />
                    </div>
                    <div className="flex flex-row w-full items-center justify-between mt-3">
                        <div className="flex">
                            {t('selectParkingSpotsModal.numberOfParkingSpotsSelected', { number: selectedParkingSpots.length })}
                        </div>
                        <ButtonText onClick={() => { closeModal(); }} className="w-80">
                            {t('generic.ok')}
                        </ButtonText>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectParkingSpotsModal;