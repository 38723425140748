import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getToken } from "context/auth";

import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";

import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';

import { useMessages } from "context/messages";
import ItemContextMenu from "../../dashboard/tableItemContextMenu/itemContextMenu";
import { removechargingStation } from "api/charging";

const ChargingStationItem = ({ chargingStation, chargingStations, setChargingStations }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();
    const navigate = useNavigate();
    const { showToast, showModal, hideModal } = useMessages();
    const [isDeleting, setIsDeleting] = useState();

    const handleRemoveChargingStation = () => {

        setShowMenu(false);

        showModal(
            t('chargingStationsTable.removeChargingStationTitle'),
            t('chargingStationsTable.removeChargingStationDescription'),
            t('generic.yes'),
            async () => {
                setIsDeleting(true);
                hideModal();

                try {
                    const token = await getToken();
                    const deleteChargingStationResult = await removechargingStation(token, chargingStation?.chargingStationExtId);

                    if (deleteChargingStationResult?.error) {
                        console.error(deleteChargingStationResult);
                    } else {
                        /* Remove charging station from charging station list */
                        const newChargingStations = chargingStations.filter((item) => item.chargingStationExtId !== chargingStation.chargingStationExtId);
                        setChargingStations(newChargingStations);

                        showToast(t('chargingStationsTable.removedChargingStationTitle'), t('chargingStationsTable.removedChargingStationDescription'), 'success');
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                setIsDeleting(false);
            },
            t('generic.cancel'),
            () => { hideModal() },
            'warning');
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">
                {chargingStation.chargingStationExtId}
            </td>
            <td className="pl-6 whitespace-nowrap">
                {chargingStation.spotIdentifier}
            </td>
            <td className="pl-6 whitespace-nowrap">
                {chargingStation.chargingProviderName}
            </td>
            <td className="pl-6 whitespace-nowrap">
                {chargingStation.chargingStationExternalId}
            </td>
            <td className="pl-6 whitespace-nowrap">
                {chargingStation.online ? `Online (${chargingStation.activeChargingSessionExtId ? t('chargingStationsTable.charging') : t('chargingStationsTable.notCharging')})` : 'Offline'}
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isDeleting &&
                    <div className="flex h-16 w-full justify-center items-center">
                        <Lottie className="h-6 w-6" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isDeleting &&
                    <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showMenu &&
                    <ItemContextMenu
                        onCloseClick={() => { setShowMenu(false); }}
                        onEditClick={() => { navigate(`edit-charging-station/${chargingStation.chargingStationExtId.toLowerCase()}`); }}
                        onDeleteClick={() => { handleRemoveChargingStation(); }} />
                }
            </td>
        </tr >
    )
}

export default ChargingStationItem;